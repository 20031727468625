import service from "@/utils/service.js"

// 商品优惠券列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/productCoupon/couponList',
		params: params
	})
}

// 商品优惠券新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/productCoupon/add',
		data
	})
}

// 商品优惠券详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/productCoupon/detail',
		params: params
	})
}

// 商品优惠券修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/productCoupon/edit',
		data
	})
}

// 商品优惠券状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/productCoupon/statusChange',
		data
	})
}

// 商品优惠券发放
export function send (data) {
	return service({
		method: 'post',
		url: 'spread/productCoupon/send',
		data
	})
}

// 商品优惠券删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/productCoupon/del',
		data
	})
}