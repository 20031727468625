var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":"文件选择","visible":_vm.dialogShow,"top":"4vh","width":"80%","before-close":_vm.handleClose,"append-to-body":true},on:{"update:visible":function($event){_vm.dialogShow=$event}}},[_c('div',{staticClass:"attachment-main"},[_c('div',{staticClass:"category-main"},[_c('el-tree',{attrs:{"data":_vm.categorylist,"props":_vm.treeProps,"highlight-current":true},on:{"node-click":_vm.categoryChange}})],1),_c('div',{staticClass:"attachment-content"},[_c('div',{staticClass:"tool"},[_c('div',{staticClass:"tool-left"},[_c('el-button',{attrs:{"type":"info","size":"mini","icon":"el-icon-refresh"},on:{"click":function($event){return _vm.resetData()}}}),_c('el-button',{attrs:{"type":"primary","size":"mini","disabled":_vm.changeFileList.length == 0},on:{"click":_vm.confirm}},[_vm._v(" 使用选中文件（"+_vm._s(_vm.changeFileList.length)+"/"+_vm._s(_vm.number)+"） ")]),_c('el-button',{staticClass:"el-icon-upload",attrs:{"type":"success","size":"mini"},on:{"click":function($event){return _vm.$refs.upload.show()}}},[_vm._v(" 上传文件 ")])],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.attachmentIsLoading),expression:"attachmentIsLoading"}],staticClass:"attachment-data",attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(255, 255, 255, 0.9)"}},[_c('div',{staticClass:"attachment-list"},[_vm._l((_vm.attachmentList),function(item,index){return _c('div',{key:index,class:[
                  'attachment-li',
                  _vm.changeFileList.indexOf(item.file_url) > -1
                    ? 'attachment-li-active'
                    : '',
                ]},[_c('div',{staticClass:"attachment-change u-flex-center",on:{"click":function($event){return _vm.attachmentChange(index)}}},[_c('div',{class:[
                      'attachment-number',
                      _vm.changeFileList.indexOf(item.file_url) > -1
                        ? 'attachment-number-active'
                        : 'attachment-number-in',
                    ]},[_vm._v(" "+_vm._s(_vm.changeFileList.indexOf(item.file_url) > -1 ? _vm.changeFileList.indexOf(item.file_url) + 1 : "")+" ")])]),(item.file_type == 'image')?_c('el-image',{staticStyle:{"width":"140px","height":"140px","display":"block"},attrs:{"fit":"cover","src":item.file_url,"lazy":true,"preview-src-list":_vm.imageUrlList,"referrer-policy":"no-referrer|origin|unsafe-url"}}):_vm._e(),(item.file_type == 'application')?_c('el-image',{staticStyle:{"width":"140px","height":"140px","display":"block"},attrs:{"src":_vm.$getSystemConfig('upload_doc_img'),"referrer-policy":"no-referrer|origin|unsafe-url"}}):_vm._e(),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-li"},[_vm._v("文件名："+_vm._s(item.file_name))]),_c('div',{staticClass:"info-li"},[_vm._v(" 所属分类："+_vm._s(item.category ? item.category.name : "无")+" ")]),(item.file_type == 'image')?_c('div',{staticClass:"info-li"},[_vm._v(" 尺寸："+_vm._s(item.image_width)+" x "+_vm._s(item.image_height)+" ")]):_vm._e(),_c('div',{staticClass:"info-li"},[_vm._v("大小："+_vm._s(item.file_size))])])],1)}),(!_vm.attachmentIsLoading && _vm.attachmentList.length == 0)?_c('el-empty',{attrs:{"description":"暂无文件"}}):_vm._e()],2)]),_c('pagination',{attrs:{"page":_vm.page,"page_size":_vm.page_size,"total":_vm.attachmentTotal,"pageSizeList":[20, 50, 100, 500]},on:{"pagination":_vm.pagination}})],1)])]),_c('upload',{ref:"upload",on:{"uploadSuccess":_vm.uploadSuccess,"close":_vm.resetData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }