export default {
	install(Vue) {
		/**
		 * @desc 文件域名拼接
		 * @param e 状态
		 */
		Vue.prototype.$selectMenu = function(path, name) {
			console.log(path, name)
			var that = this;
			let navList = this.$getStorage("navList") || [];
			if (navList.indexOf('/' + path) > -1) { // 不存在
				navList.push({
					path: path,
					label: name,
					name: path,
				});
			}
			console.log(navList)
		}
	}
}