<template>
  <div class="select-choose-main u-flex">
    <div class="select-choose-content u-flex" v-if="list.length > 0">
      <el-tag
        size="mini"
        v-for="(item, index) in list"
        :key="index"
        closable
        @close="$emit('del', index)"
        >{{ item[textName] }}</el-tag
      >
    </div>
    <el-button size="mini" type="text" @click="$emit('open')">选择</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    textName: {
      type: String,
      default: "name",
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.select-choose-main {
  min-height: 40px;

  .select-choose-content {
    margin-right: 10px;
  }
}
</style>
