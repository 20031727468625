export default {
	install(Vue) {
		/**
		 * @desc 文件域名拼接
		 * @param e 状态
		 */
		Vue.prototype.$baseFile = function(e) {
			var regex = /^((?:[a-z]+:)?\/\/|data:image\/)(.*)/i
			// 匹配是否有域名或base64
			if (e && e.search(regex) == -1) { // 未匹配到
				e = this.$getSystemConfig('qn_domain_name') + e
			}
			return e
		}
		/**
		 * @desc 订单类型转文字
		 * @param e 参数值
		 */
		Vue.prototype.$orderTypeText = function(e) {
			var list = {
				1: '普通商品',
				2: '秒杀',
				3: '活动',
				4: '积分商城',
			}
			return list[e]
		}
		/**
		 * @desc 订单支付类型转文字
		 * @param e 参数值
		 */
		Vue.prototype.$orderPaymentMethodText = function(e) {
			var list = {
				'': '无',
				'wechat': '微信支付',
				'baidu': '百度支付',
				'alipay': '支付宝支付',
				'offline': '线下支付',
			}
			return list[e]
		}
		/**
		 * @desc 订单收货类型转文字
		 * @param e 参数值
		 */
		Vue.prototype.$orderReceiptTypeText = function(e) {
			var list = {
				1: '用户确认',
				2: '后台确认',
				3: '自动确认',
			}
			return list[e]
		}
		/**
		 * @desc 订单状态转文字
		 * @param e 参数值
		 */
		Vue.prototype.$orderStatusText = function(e) {
			var list = {
				0: '未支付',
				1: '待配送',
				2: '已配送',
				3: '已完成',
				4: '已取消',
			}
			return list[e]
		}
		/**
		 * @desc 商品优惠券使用范围转文字
		 * @param e 参数值
		 */
		Vue.prototype.$couponUseRangeText = function(e) {
			var list = {
				1: '全场',
				2: '指定分类',
				3: '指定商品',
			}
			return list[e]
		}
		/**
		 * @desc 商品优惠券优惠方式转文字
		 * @param e 参数值
		 */
		Vue.prototype.$couponDiscountMethodText = function(e) {
			var list = {
				1: '满减',
				2: '直减',
				3: '折扣',
			}
			return list[e]
		}
		/**
		 * @desc 余额获得类型
		 * @param e 参数值
		 */
		Vue.prototype.$balanceIncomeType = function(e) {
			var list = {
				0: '非充值',
				1: '充值',
				2: '退款',
			}
			return list[e]
		}
		/**
		 * @desc 余额获得类型
		 * @param e 参数值
		 */
		Vue.prototype.$balanceType = function(e) {
			var list = {
				1: '收入',
				2: '支出',
			}
			return list[e]
		}
		/**
		 * @desc 积分收入类型
		 * @param e 参数值
		 */
		Vue.prototype.$pointIncome = function(e) {
			var list = {
				0: '非收入',
				1: '购买商品获得',
				2: '退款获得',
			}
			return list[e]
		}
		/**
		 * @desc 佣金状态
		 * @param e 参数值
		 */
		Vue.prototype.$status = function(e) {
			var list = {
				0: '禁用',
				1: '正常',
			}
			return list[e]
		}
		/**
		 * @desc 佣金账户类型
		 * @param e 参数值
		 */
		Vue.prototype.$account_type = function(e) {
			var list = {
				1: '微信',
				2: '支付宝',
				3: '银行卡',
			}
			return list[e]
		}
		/**
		 * @desc 佣金状态
		 * @param e 参数值
		 */
		Vue.prototype.$commissionStatus = function(e) {
			var list = {
				0: '待审核',
				1: '审核通过',
				2: '审核未通过',
				3: '已打款',
			}
			return list[e]
		}
		/**
		 * @desc 优惠券领取方式转文字
		 * @param e 参数值
		 */
		Vue.prototype.$couponReceivingMethodText = function(e) {
			var list = {
				'1': '手动领取',
				'2': '新人自动领取',
				'3': '后台发放',
			}
			return list[e]
		}
		/**
		 * @desc 优惠券优惠方式转文字
		 * @param e 参数值
		 */
		Vue.prototype.$couponDiscountMethodText = function(e) {
			var list = {
				'1': '满减',
				'2': '直减',
				'3': '折扣',
			}
			return list[e]
		}
		/**
		 * @desc 佣金提现到账类型转文字
		 * @param e 参数值
		 */
		Vue.prototype.$commissionWithdrawalAccountTypeText = function(e) {
			var list = {
				'wechat': '微信',
				'baidu': '百度',
				'alipay': '支付宝',
				'bankcard': '银行卡',
			}
			return list[e]
		}
		/**
		 * @desc 佣金提现类型转文字
		 * @param e 参数值
		 */
		Vue.prototype.$commissionWithdrawalStatusText = function(e) {
			var list = {
				'0': '待审核',
				'1': '审核通过',
				'2': '审核未通过',
				'3': '已打款',
			}
			return list[e]
		}

	}
}