import service from "@/utils/service.js"

// 列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/groupDiscount/list',
		params: params
	})
}

// 详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/groupDiscount/detail',
		params: params
	})
}

// 状态开关
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/groupDiscount/statusChange',
		data
	})
}

// 添加
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/groupDiscount/add',
		data
	})
}

// 修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/groupDiscount/edit',
		data
	})
}


// 删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/groupDiscount/del',
		data
	})
}