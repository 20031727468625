import service from "@/utils/service.js"

// 列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/product/list',
		params: params
	})
}

// 选择列表
export function selectList (params) {
	return service({
		method: 'get',
		url: '/spread/product/selectList',
		params: params
	})
}

// 列表拖拽排序
export function sorts (data) {
	return service({
		method: 'post',
		url: '/spread/product/setSorts',
		data
	})
}

// 新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/product/add',
		data
	})
}

// 详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/product/detail',
		params: params
	})
}

// 修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/product/edit',
		data
	})
}

// 批量修改状态
export function batchEditStatus (data) {
	return service({
		method: 'post',
		url: '/spread/product/batchEditStatus',
		data
	})
}

// 批量修改分类
export function batchEditCategory (data) {
	return service({
		method: 'post',
		url: '/spread/product/batchEditCategory',
		data
	})
}

// 详情
export function getReduction (params) {
	return service({
		method: 'get',
		url: '/spread/product/getReduction',
		params: params
	})
}

// 系统商品
export function systemProductList (params) {
	return service({
		method: 'get',
		url: '/spread/systemProduct/list',
		params: params
	})
}

// 修改
export function reduction (data) {
	return service({
		method: 'post',
		url: '/spread/product/reduction',
		data
	})
}

// 推荐切换
export function isRecommendChange (data) {
	return service({
		method: 'post',
		url: '/spread/product/isRecommendChange',
		data
	})
}

// 状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/product/statusChange',
		data
	})
}

// 是否免邮
export function store (data) {
	return service({
		method: 'post',
		url: '/spread/product/store',
		data
	})
}

// 删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/product/del',
		data
	})
}

// 新增导入
export function importAdd (data) {
	return service({
		method: 'post',
		url: '/spread/product/importAdd',
		data
	})
}

// 新增导入
export function importExcelEdit (data) {
	return service({
		method: 'post',
		url: '/spread/product/importExcelEdit',
		data
	})
}

// 获取导入修改商品数据
export function getImportEditInfo (data) {
	return service({
		method: 'post',
		url: '/spread/product/getImportEdit',
		data
	})
}

// 导入修改商品
export function importEditInfo (data) {
	return service({
		method: 'post',
		url: '/spread/product/importEdit',
		data
	})
}

// 同步系统品牌
export function synSystemBrand (data) {
	console.log(data)
	return service({
		method: 'post',
		url: '/spread/systemProduct/batchCopySpreadProductBrand',
		data
	})
}

// 同步系统商品分类
export function synSystemCategory (data) {
	return service({
		method: 'post',
		url: '/spread/systemProduct/batchCopySpreadProductCategory',
		data
	})
}

// 同步系统商品
export function synSystemProduct (data) {
	return service({
		method: 'post',
		url: '/spread/systemProduct/batchCopySysProduct',
		data
	})
}

// 同步系统商品上下架状态
export function synSystemProductStatuses (data) {
	return service({
		method: 'post',
		url: '/spread/systemProduct/synProductStatus',
		data
	})
}

// 导出平台商品
export function exportExcelProduct (data) {
	return service({
		method: 'post',
		url: '/spread/product/exportExcel',
		data
	})
}

// 导出全部系统商品
export function exportExcelSystemProduct (data) {
	return service({
		method: 'post',
		url: '/spread/product/exportSystemProduct',
		data
	})
}

// 初始化商品售价
export function initProductPrice (data) {
	return service({
		method: 'post',
		url: '/spread/product/initProductPrice',
		data
	})
}