import {
	errorMsg,
	successMsg,
} from '@/utils/message.js'
// 公共js文件
export default {
	install (Vue) {
		/**
		 * @desc 获取系统配置
		 * @param param  参数名
		 */
		Vue.prototype.$getSystemConfig = function (param = '') {
			// 从缓存中获取系统配置
			var systemConfig = this.$getStorage('systemConfig')
			systemConfig = JSON.parse(systemConfig)
			if (systemConfig[param] !== undefined) {
				var res = systemConfig[param]
			} else {
				var res = systemConfig
			}
			return res
		}
		/**
		 * @desc 文件域名拼接
		 * @param e 状态
		 */
		Vue.prototype.$deepClone = function (obj) {
			//      定义一个变量 并判断是数组还是对象
			var objClone = Array.isArray(obj) ? [] : {}
			if (obj && typeof obj === "object" && obj != null) {
				// 判断obj存在并且是对象类型的时候 因为null也是object类型，所以要单独做判断
				for (var key in obj) { // 循环对象类型的obj
					if (obj.hasOwnProperty(key)) { // 判断obj中是否存在key属性
						if (obj[key] && typeof obj[key] ===
							"object") { //  判断如果obj[key]存在并且obj[key]是对象类型的时候应该深拷贝，即在堆内存中开辟新的内存
							objClone[key] = Vue.prototype.$deepClone(obj[key])
						} else { //  否则就是浅复制
							objClone[key] = obj[key]
						}
					}
				}
			}
			return objClone
		}
		/**
		 * @desc 获取转文字
		 * @param name  参数名
		 * @param value  参数值
		 */
		Vue.prototype.$toText = function (name = '', value = '') {
			var toText = this.$getStorage('toText')
			toText = JSON.parse(toText)
			// var toText = this.$store.state.init.toText;
			if (toText[name] === undefined) {
				return ''
			} else {
				return toText[name][value] || '无'
			}
		}
		/**
		 * @desc 获取时间
		 */
		Vue.prototype.$formatDate = function () {
			let date = new Date()
			let year = date.getFullYear() // 年
			let month = date.getMonth() + 1 // 月
			let day = date.getDate() // 日
			let week = date.getDay() // 星期
			let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
			let hour = date.getHours() // 时
			hour = hour < 10 ? "0" + hour : hour // 如果只有一位，则前面补零
			let minute = date.getMinutes() // 分
			minute = minute < 10 ? "0" + minute : minute // 如果只有一位，则前面补零
			let second = date.getSeconds() // 秒
			second = second < 10 ? "0" + second : second // 如果只有一位，则前面补零
			var nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`
			return nowDate
		}
		/**
		 * @desc 判断当前设备是否为移动端
		 */
		Vue.prototype.$isMobileDevice = function () {
			const ua = navigator.userAgent.toLowerCase()
			const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
				ua
			)
			const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1
			return t1 || t2
		}

		/**
		 * @desc 判断当前设备是手机
		 */
		Vue.prototype.$isMobilePhone = function () {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		}

		/**
		 * @desc 判断当前设备是手机
		 */
		Vue.prototype.$isIOS = function () {
			// const ua = navigator.userAgent;
			// return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			var u = window.navigator.userAgent
			var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //g
			return !isAndroid
		}
		Vue.prototype.$trimSpace = function (array) {
			for (var i = 0; i < array.length; i++) {
				if (array[i] == "" || array[i] == null || typeof (array[i]) == "undefined") {
					array.splice(i, 1)
					i = i - 1
				}
			}
			return array
		}
		// 数组去重
		Vue.prototype.$arrayUnique = function (array) {
			var newArr = []
			for (var i = 0; i < array.length; i++) {
				if (newArr.indexOf(array[i]) == -1) {
					// 等式存元素到新数组
					newArr[newArr.length] = array[i]
				}
			}
			return newArr
		}
		// wangEditor配置信息
		Vue.prototype.$wangEditorConfig = function () {
			let editorConfig = {
				placeholder: '请输入内容...',
				MENU_CONF: {
					uploadImage: {
						fieldName: 'file',
						server: 'https://api.ydyxw.ydyxw.cn/admin/attachment/upload',
						// 自定义增加 http  header
						headers: {
							Authorization: this.$getStorage('token')
						},
						// 超时时间，默认为 10 秒
						timeout: 60 * 1000, // 60 秒
						// 单个文件的最大体积限制，默认为 2M
						maxFileSize: 1 * 1024 * 1024, // 1M
						// 最多可上传几个文件，默认为 100
						maxNumberOfFiles: 5,
						// 自定义插入图片
						customInsert (res, insertFn) { // JS 语法
							// res 即服务端的返回结果
							// 从 res 中找到 url alt href ，然后插入图片
							insertFn(res.data.base_file_url, '', res.data.file_url)
						},
						// 上传之前触发
						onBeforeUpload (file) { // JS 语法
							// file 选中的文件，格式如 { key: file }
							return file
							// 可以 return
							// 1. return file 或者 new 一个 file ，接下来将上传
							// 2. return false ，不上传这个 file
						},
						// 单个文件上传成功之后
						onSuccess (file, res) { // JS 语法
							successMsg(res.msg)
						},
						// 单个文件上传失败
						onFailed (file, res) { // JS 语法
							errorMsg(res.msg)
						},
						// 上传错误，或者触发 timeout 超时
						onError (file, err, res) { // JS 语法
							errorMsg('上传出错')
						},
					}
				},
			}
			let toolbarConfig = {}
			return {
				mode: 'default', // or 'simple'
				editorConfig: editorConfig,
				toolbarConfig: toolbarConfig
			}
		}
		/**
		 * @desc 获取列表高度
		 * @param {Number} toolHeight 工具栏高度
		 * @param {Boolean} pagination 分页 true-存在 false-不存在
		 */
		Vue.prototype.$getListHeight = function (toolHeight = 0, pagination = true) {
			// // 获取列表高度
			// getListHeight() {
			// 	setTimeout(() => {
			// 		// 获取列表高度
			// 		this.tableHeight = this.$getListHeight(this.$refs.tableTool.offsetHeight);
			// 	}, 1)
			// },
			// toolHeight = 331;
			// 头部高度
			var headerHeight = 100
			// 面包屑高度
			var breadcrumbHeight = 30 + 14
			// 主体内容内边距
			var mainContentPadding = 40
			// 列表内边距
			var tablePadding = 40
			// 分页高度
			var paginationHeight = 20
			// 分页状态
			if (pagination) {
				// 分页高度
				paginationHeight += 52
			}
			var tableHeight = window.innerHeight - (headerHeight + toolHeight + breadcrumbHeight +
				mainContentPadding + tablePadding + paginationHeight)
			return tableHeight
		}
		/**
		 * 递归禁用
		 * @param data 数据列表
		 * @param current_id 当前id
		 * @param parent_disabled 父级是否禁用
		 */
		Vue.prototype.$recurrenceDisabled = function (data, current_id, parent_disabled = false) {
			// return new Promise((resolve, reject) => {
			// 	setTimeout(() => {
			// 		resolve("第一个请求成功")
			// 	}, 1000)
			// })
			new Promise((resolve, reject) => {
				let count = 0
				for (let i in data) {
					data[i].index = i
					data[i].disabled = false
					if (parent_disabled || data[i].id == current_id) {
						data[i].disabled = true
					}
					if (data[i].children) {
						count++
						recurrenceDisabled(data[i].children, data[i].disabled)
					}
				}
				resolve(data)
			})
			// let count = 0;
			// for (let i in data) {
			// 	data[i].index = i;
			// 	data[i].disabled = false;
			// 	if (parent_disabled || data[i].id == current_id) {
			// 		data[i].disabled = true;
			// 	}
			// 	if (data[i].children) {
			// 		count++;
			// 		recurrenceDisabled(data[i].children, data[i].disabled);
			// 	}
			// }
			// return data;
		}

	}
}