import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import router from './router'
// 引入vue-router --------------------
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// 引入store
import store from '@/store'
Vue.prototype.$store = store

// 引入富文本
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)


// import {
// 	createPinia,
// 	PiniaVuePlugin
// } from "pinia";
// // Vue.use(createPinia)
// const pinia = createPinia();
// Vue.use(PiniaVuePlugin);
// 全局组件注册
import install from '@/components/install.js'
Vue.use(install)
//  引入Echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
// 缓存操作
import storage from '@/utils/storage.js'
Vue.use(storage)
// 气泡提示框
import message from '@/utils/message.js'
Vue.use(message)
// 加载框
import loading from '@/utils/loading.js'
Vue.use(loading)
// 引用公共文件
import common from '@/utils/common.js'
Vue.use(common)
// 数据转字符串
import toText from '@/utils/toText.js'
Vue.use(toText)
// 精度计算
import math from '@/utils/math.js'
Vue.use(math)
// 精度计算
import navbar from '@/utils/navbar.js'
Vue.use(navbar)
// 复制、剪贴板
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
Vue.config.productionTip = false
new Vue({
	router,
	// pinia,
	render: h => h(App),
}).$mount('#app')