import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("../views/index/Login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    component: () => import("../views/index/Index.vue"),
    redirect: "/welcome",
    children: [
      {
        path: "/welcome",
        component: () => import("../views/achievement/Index.vue"),
        meta: {
          title: "美条街Saas后台管理系统",
        },
      },
      {
        path: "/admin",
        component: () => import("../views/admin/Index.vue"),
        meta: {
          title: "管理员列表",
        },
      },
      {
        path: "/power",
        component: () => import("../views/power/Index.vue"),
        meta: {
          title: "权限列表",
        },
      },
      {
        path: "/user",
        component: () => import("../views/user/Index.vue"),
        meta: {
          title: "用户列表",
        },
      },
      {
        path: "/userConfig",
        component: () => import("../views/systemConfig/User.vue"),
        meta: {
          title: "用户配置",
        },
      },
      {
        path: "/marketingConfig",
        component: () => import("../views/systemConfig/Marketing.vue"),
        meta: {
          title: "营销配置",
        },
      },
      {
        path: "/distributionConfig",
        component: () => import("../views/systemConfig/Distribution.vue"),
        meta: {
          title: "分销配置",
        },
      },
      {
        path: "/orderConfig",
        component: () => import("../views/systemConfig/Order.vue"),
        meta: {
          title: "订单配置",
        },
      },
      {
        path: "/renovationConfig",
        component: () => import("../views/systemConfig/Renovation.vue"),
        meta: {
          title: "装修配置",
        },
      },
      {
        path: "/agreementConfig",
        component: () => import("../views/systemConfig/agreement.vue"),
        meta: {
          title: "协议配置",
        },
      },
      {
        path: "/payConfig",
        component: () => import("../views/systemConfig/Payment.vue"),
        meta: {
          title: "支付配置",
        },
      },
      {
        path: "/membersLevel",
        component: () => import("../views/membersLevel/Index.vue"),
        meta: {
          title: "会员等级",
        },
      },
      {
        path: "/membersRecord",
        component: () => import("../views/membersRecord/Index.vue"),
        meta: {
          title: "会员记录",
        },
      },
      {
        path: "/product",
        component: () => import("../views/product/Index.vue"),
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "/productBrand",
        component: () => import("../views/productBrand/Index.vue"),
        meta: {
          title: "品牌列表",
        },
      },
      {
        path: "/productBrandCategory",
        component: () => import("../views/productBrandCategory/Index.vue"),
        meta: {
          title: "品牌类目",
        },
      },
      {
        path: "/productCategory",
        component: () => import("../views/productCategory/Index.vue"),
        meta: {
          title: "分类列表",
        },
      },
      {
        path: "/integralProduct",
        component: () => import("../views/integralProduct/Index.vue"),
        meta: {
          title: "积分商品列表",
        },
      },
      {
        path: "/integralExchangeRecords",
        component: () => import("../views/integral-exchange-records/Index.vue"),
        meta: {
          title: "积分兑换记录",
        },
      },
      {
        path: "/integralRecord",
        component: () => import("../views/integralRecord/Index.vue"),
        meta: {
          title: "积分记录",
        },
      },
      {
        path: "/integralProductCertificate",
        component: () =>
          import("../views/integralProductCertificate/Index.vue"),
        meta: {
          title: "卡券记录",
        },
      },
      {
        path: "/boostProduct",
        component: () => import("../views/boostProduct/Index.vue"),
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "/boostRecord",
        component: () => import("../views/boostRecord/Index.vue"),
        meta: {
          title: "助力记录",
        },
      },
      {
        path: "/productCoupon",
        component: () => import("../views/productCoupon/Index.vue"),
        meta: {
          title: "优惠券列表",
        },
      },
      {
        path: "/order",
        component: () => import("../views/order/Index.vue"),
        meta: {
          title: "订单列表",
        },
      },
      {
        path: "/orderRefund",
        component: () => import("../views/orderRefund/Index.vue"),
        meta: {
          title: "售后订单",
        },
      },
      {
        path: "/productCouponRecord",
        component: () => import("../views/productCouponRecord/Index.vue"),
        meta: {
          title: "领取记录",
        },
      },
      {
        path: "/incomeBill",
        component: () => import("../views/incomeBill/Index.vue"),
        meta: {
          title: "收入账单",
        },
      },
      {
        path: "/commissionBill",
        component: () => import("../views/commissionBill/Index.vue"),
        meta: {
          title: "佣金账单",
        },
      },
      {
        path: "/commissionWithdrawal",
        component: () => import("../views/commissionWithdrawal/Index.vue"),
        meta: {
          title: "佣金账单",
        },
      },
      {
        path: "/userSaleStatistic",
        component: () => import("../views/userSaleStatistic/Index.vue"),
        meta: {
          title: "代理业绩",
        },
      },
      {
        path: "/attachmentCategory",
        component: () => import("../views/attachmentCategory/Index.vue"),
        meta: {
          title: "附件分类",
        },
      },
      {
        path: "/attachment",
        component: () => import("../views/attachment/Index.vue"),
        meta: {
          title: "附件列表",
        },
      },
      {
        path: "/banner",
        component: () => import("../views/banner/Index.vue"),
        meta: {
          title: "轮播图",
        },
      },
      {
        path: "/systemConfig",
        component: () => import("../views/systemConfig/Index.vue"),
        meta: {
          title: "系统配置",
        },
      },
      {
        path: "/returnProductAddress",
        component: () => import("../views/returnProductAddress/Index.vue"),
        meta: {
          title: "退货地址",
        },
      },
      {
        path: "/seckillProduct",
        component: () => import("../views/seckillProduct/Index.vue"),
        meta: {
          title: "限时秒杀",
        },
      },
      {
        path: "/userStatistic",
        component: () => import("../views/statistic/user.vue"),
        meta: {
          title: "用户数据",
        },
      },
      {
        path: "/orderStatistic",
        component: () => import("../views/statistic/order.vue"),
        meta: {
          title: "订单数据",
        },
      },
      {
        path: "/institution",
        component: () => import("../views/institution/Index.vue"),
        meta: {
          title: "机构列表",
        },
      },
      {
        path: "/institutionAgent",
        component: () => import("../views/institutionAgent/Index.vue"),
        meta: {
          title: "机构代理",
        },
      },
      {
        path: "/productBrandType",
        component: () => import("../views/productBrandType/Index.vue"),
        meta: {
          title: "品牌类型",
        },
      },
      {
        path: "/brandPavilionType",
        component: () => import("../views/brandPavilionType/Index.vue"),
        meta: {
          title: "品牌馆类型",
        },
      },
      {
        path: "/brandPavilion",
        component: () => import("../views/brandPavilion/Index.vue"),
        meta: {
          title: "品牌馆",
        },
      },
      {
        path: "/medicalCosmetologyZone",
        component: () => import("../views/medicalCosmetologyZone/Index.vue"),
        meta: {
          title: "医美专区",
        },
      },
      {
        path: "/gift",
        component: () => import("../views/gift/Index.vue"),
        meta: {
          title: "赠品列表",
        },
      },
      {
        path: "/activityGift",
        component: () => import("../views/activityGift/Index.vue"),
        meta: {
          title: "赠品列表",
        },
      },
      {
        path: "/delivery",
        component: () => import("../views/delivery/Index.vue"),
        meta: {
          title: "配送列表",
        },
      },
      {
        path: "/distributionGrade",
        component: () => import("../views/distributionGrade/Index.vue"),
        meta: {
          title: "分销等级",
        },
      },
      {
        path: "/subscribe",
        component: () => import("../views/subscribe/Index.vue"),
        meta: {
          title: "公众号订阅",
        },
      },
      {
        path: "/subscribe",
        component: () => import("../views/subscribe/Index.vue"),
        meta: {
          title: "公众号订阅",
        },
      },
      {
        path: "/empower",
        component: () => import("../views/client/Index.vue"),
        meta: {
          title: "授权列表",
        },
      },
      {
        path: "/account",
        component: () => import("../views/account/Index.vue"),
        meta: {
          title: "账户列表",
        },
      },
      {
        path: "/groupDiscount",
        component: () => import("../views/userGroupDiscount/Index.vue"),
        meta: {
          title: "折扣分组",
        },
      },
      {
        path: "/userGrade",
        component: () => import("../views/userGrade/Index.vue"),
        meta: {
          title: "用户等级",
        },
      },
      {
        path: "/userRechargeGive",
        component: () => import("../views/userRechargeGive/Index.vue"),
        meta: {
          title: "充值方案",
        },
      },
      {
        path: "/rechargeBill",
        component: () => import("../views/rechargeBill/Index.vue"),
        meta: {
          title: "充值账单",
        },
      },
      {
        path: "/achievement",
        component: () => import("../views/achievement/Index.vue"),
        meta: {
          title: "业绩统计",
        },
      },
      {
        path: "/secKill",
        component: () => import("../views/secKill/Index.vue"),
        meta: {
          title: "秒杀时段",
        },
      },
      {
        path: "/secKillActivity",
        component: () => import("../views/secKillActivity/Index.vue"),
        meta: {
          title: "秒杀活动",
        },
      },
      {
        path: "/wxMiniMessageTemplate",
        component: () => import("../views/wxMiniMessageTemplate/Index.vue"),
        meta: {
          title: "微信小程序消息模板",
        },
      },
      {
        path: "/claimRecord",
        component: () => import("../views/claimRecord/Index.vue"),
        meta: {
          title: "红包领取记录",
        },
      },
      {
        path: "/redEnvelope",
        component: () => import("../views/redEnvelope/Index.vue"),
        meta: {
          title: "红包活动",
        },
      },
      {
        path: "/agent",
        component: () => import("../views/agent/Index.vue"),
        meta: {
          title: "代理列表",
        },
      },
      {
        path: "/product-selection",
        component: () => import("../views/product-selection/Index.vue"),
        meta: {
          title: "选品中心",
        },
      },
      {
        path: "/product-comment",
        component: () => import("../views/product-comment/Index.vue"),
        meta: {
          title: "商品评论",
        },
      },
      {
        path: "/medicalProduct",
        component: () => import("../views/medicalProduct/Index.vue"),
        meta: {
          title: "医疗商品列表",
        },
      },
      {
        path: "/medicalCategory",
        component: () => import("../views/medicalCategory/Index.vue"),
        meta: {
          title: "医疗商品分类",
        },
      },
      {
        path: "/medicalBrand",
        component: () => import("../views/medicalBrand/Index.vue"),
        meta: {
          title: "医疗商品品牌",
        },
      },
      {
        path: "/enterprise",
        component: () => import("../views/enterprise/Index.vue"),
        meta: {
          title: "企业申请",
        },
      },
      {
        path: "/complaint",
        component: () => import("../views/complaint/Index.vue"),
        meta: {
          title: "反馈与投诉",
        },
      },
      {
        path: "/productCouponPackage",
        component: () => import("../views/product-coupon-package/Index.vue"),
        meta: {
          title: "券包列表",
        },
      },
      {
        path: "/orderCouponPackage",
        component: () => import("../views/orderCouponPackage/Index.vue"),
        meta: {
          title: "券包购买记录",
        },
      },
      {
        path: "/staff",
        component: () => import("../views/staff/Index.vue"),
        meta: {
          title: "员工列表",
        },
      },
      {
        path: "/distributionName",
        component: () => import("../views/distributionName/Index.vue"),
        meta: {
          title: "分销实名认证",
        },
      },
      {
        path: "/productEfficacy",
        component: () => import("../views/productEfficacy/Index.vue"),
        meta: {
          title: "商品功效",
        },
      },
      {
        path: "/log",
        component: () => import("../views/log/Index.vue"),
        meta: {
          title: "日志列表",
        },
      },
      {
        path: "/logConfig",
        component: () => import("../views/logConfig/Index.vue"),
        meta: {
          title: "日志配置列表",
        },
      },
      {
        path: "/staffAchievement",
        component: () => import("../views/staffAchievement/Index.vue"),
        meta: {
          title: "员工业绩统计",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  let title = "美条街Saas后台管理系统";
  document.title = to.meta.title ? to.meta.title + " - " + title : title;
  // to将要访问的路径
  // from 代表从哪个路径跳转而来
  // next s是一个函数，表示放行
  //      next() 放行
  // 获取token
  const tokenStr = window.localStorage.getItem("token");
  if (tokenStr) {
    // 已登陆
    // 如果是跳转到登录页
    if (to.path === "/login") {
      // 进入首页
      return next("/");
    }
    return next();
  } else {
    // 未登录
    // 如果是进入登录页，不这样判断会报错
    if (to.path !== "/login") {
      return next("/login");
    }
    return next();
  }
  // if (to.path === '/login') return next();
  // // 获取token
  // const tokenStr = window.localStorage.getItem('token')
  // if (!tokenStr) return next('/login')
  // next();
});

export default router;
