import service from "@/utils/service.js"

// 助力商品列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/boostProduct/index',
		params: params
	})
}

// 助力商品选择列表
export function selectList (params) {
	return service({
		method: 'get',
		url: '/spread/boostProduct/selectList',
		params: params
	})
}

// 助力商品新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/boostProduct/add',
		data
	})
}

// 助力商品详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/boostProduct/detail',
		params: params
	})
}

// 助力商品修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/boostProduct/edit',
		data
	})
}

// 助力商品状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/boostProduct/statusChange',
		data
	})
}

// 助力商品删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/boostProduct/del',
		data
	})
}

// 列表拖拽排序
export function sorts (data) {
	return service({
		method: 'post',
		url: '/spread/boostProduct/setSorts',
		data
	})
}