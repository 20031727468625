<template>
    <div>
        <image-button :list="list" image-name="cover_image" :is-move="multiple" @del="del($event)" @open="open()"
                      @move="move($event)"></image-button>
        <el-dialog title="商品选择" :visible.sync="show" :before-close="handleClose" width="60%"
                   :close-on-click-modal="false" :append-to-body="true">
            <div class="search">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="编码">
                        <el-input size="mini" v-model="params.supplier_product_number" placeholder="请输入供应商编码"></el-input>
                    </el-form-item>
                    <el-form-item label="商品">
                        <el-input size="mini" v-model="params.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" @click="getSelectList">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table height="400" v-loading="loading" :data="tableData" tooltip-effect="dark" highlight-current-row
                      style="width: 100%" @selection-change="selectionChange">
                <el-table-column type="selection" width="55" align="center" v-if="multiple">
                </el-table-column>
                <el-table-column property="id" label="ID" min-width="120" align="center">
                </el-table-column>
                <el-table-column prop="cover_image" label="图片" min-width="150" align="center">
                    <template slot-scope="scope">
                        <el-image v-show="scope.row.cover_image" :src="scope.row.cover_image" fit="contain"
                                  class="table-image">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column property="name" label="名称" min-width="240" align="center">
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="100" align="center" v-if="!multiple">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="change(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page u-flex-between">
                <pagination :page="params.page" :pageSize="10" :total="total" :page_sizeList="[10, 50, 100, 200, 500]"
                            @pagination="handlePage"/>
                <div class="submit" v-if="multiple">
                    <el-button size="mini" type="primary" @click="confirm">确 定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    selectList
} from "@/api/product.js"
import imageButton from "./select-choose-button/image-button.vue"

export default {
    components: {
        imageButton,
    },
    data() {
        return {
            show: false,
            loading: true,
            params: {
                page: 1,
                page_size: 10,
                name: "",
                supplier_product_number: "",
            },
            total: 0,
            tableData: [],
            selectList: [],
            selectIds: [],
        }
    },
    props: {
        multiple: {
            type: Boolean, // true-多选 false-单选
            default: false,
        },
        list: {
            type: Array,
            default: () => {
                return []
            },
        },
        name: {
            type: [Number, String],
            default: "",
        },
        brandId: {
            type: [Number, String],
            default: "",
        },
        brandCategoryId: {
            type: [Number, String],
            default: "",
        },
        idList: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    methods: {
        // 打开
        open() {
            this.show = true
            this.params.page = 1
            this.params.page_size = 10
            if (this.brandId) {
                this.params.brand_id = this.brandId
            }
            if (this.brandCategoryId) {
                this.params.brand_category_id = this.brandCategoryId
            }
            this.getSelectList()
        },
        // 获取商品列表
        getSelectList() {
            this.loading = true
            selectList(this.params).then((res) => {
                this.loading = false
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        // 选中商品
        selectionChange(e) {
            this.selectList = e
            this.selectIds = e.map((item) => item.id)
        },
        handleClose() {
            this.selectList = []
            this.selectIds = []
            this.show = false
        },
        handlePage(e) {
            this.params.page = e.page
            this.params.page_size = e.page_size
            this.getSelectList()
        },
        move(e) {
            var list = [];
            var idList = [];
            e.forEach((item) => {
                idList.push(item.id)
                list.push(item)
            })
            this.$emit("confirm", {
                name: this.name,
                list: list,
                ids: idList,
            })
        },
        // 删除
        del(index) {
            this.list.splice(index, 1)
            this.idList.splice(index, 1)
            this.$emit("confirm", {
                name: this.name,
                list: this.list,
                ids: this.idList,
            })
        },
        // 单选
        change(e) {
            this.selectList = [e]
            this.selectIds = [e.id]
            this.confirm()
        },
        resetData() {
            this.params.name = '';
            this.params.supplier_product_number = '';
            this.params.page = 1;
            this.params.page_size = 10;
            this.selectList = [];
            this.selectIds = [];
        },
        // 提交
        confirm() {
            if (this.multiple) {
                // 多选
                this.list.forEach((item) => {
                    // 查询下标是否已存在
                    var index = this.selectList.findIndex((v) => v.id === item.id)
                    if (index > -1) {
                        this.selectList.splice(index, 1)
                        this.selectIds.splice(index, 1)
                    }
                })
                var params = {
                    name: this.name,
                    list: this.list.concat(this.selectList),
                    ids: this.idList.concat(this.selectIds),
                }
            } else {
                // 单选
                var params = {
                    name: this.name,
                    list: this.selectList,
                    ids: this.selectIds,
                }
            }
            this.$emit("confirm", params)
            // 隐藏弹窗
            this.show = false
        },
    },
}
</script>

<style lang="scss" scoped>
</style>