import service from "@/utils/service.js"

// 附件列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/attachmentCategory/index',
		params: params
	})
}

// 附件新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/attachmentCategory/add',
		data
	})
}

// 附件详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/attachmentCategory/detail',
		params: params
	})
}

// 附件修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/attachmentCategory/edit',
		data
	})
}

// 附件状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/attachmentCategory/statusChange',
		data
	})
}

// 附件删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/attachmentCategory/del',
		data
	})
}
