<template>
	<div>
		<image-button :list="list" image-name="gift_image" @del="del($event)" @open="open()"></image-button>
		<el-dialog title="商品选择" :visible.sync="show" :before-close="handleClose" width="60%"
			:close-on-click-modal="false" :append-to-body="true">
			<div class="seacrh">
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item label="供应商编码">
						<el-input size="mini" v-model="params.supplier_product_number"
							placeholder="请输入供应商编码"></el-input>
					</el-form-item>
					<el-form-item label="赠品">
						<el-input size="mini" v-model="params.gift_name" placeholder="请输入赠品名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="mini" @click="getSelectList">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table height="400" v-loading="loading" :data="tableData" tooltip-effect="dark" highlight-current-row
				style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" align="center" v-if="multiple">
				</el-table-column>
				<el-table-column property="gift_id" label="ID" min-width="120" align="center">
				</el-table-column>
				<el-table-column prop="gift_image" label="图片" min-width="150" align="center">
					<template slot-scope="scope">
						<el-image v-show="scope.row.gift_image" :src="scope.row.gift_image" fit="contain"
							class="table-image">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column property="gift_name" label="名称" min-width="240" align="center">
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="100" align="center" v-if="!multiple">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="change(scope.row)">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="page u-flex-between">
				<pagination :page="params.page" :pageSize="10" :total="total" :pageSizeList="[10, 50, 100, 200, 500]"
					@pagination="handlePage" />
				<div class="submit" v-if="multiple">
					<el-button size="mini" type="primary" @click="confirm">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list
	} from "@/api/gift.js"
	import imageButton from "./select-choose-button/image-button.vue"
	export default {
		components: {
			imageButton,
		},
		data() {
			return {
				show: false,
				loading: true,
				params: {
					page: 1,
					page_size: 10,
					gift_name: "",
					supplier_product_number: "",
				},
				total: 0,
				tableData: [],
				selectList: [],
				selectIds: [],
			}
		},
		props: {
			multiple: {
				type: Boolean, // true-多选 false-单选
				default: false,
			},
			list: {
				type: Array,
				default: () => {
					return []
				},
			},
			brandId: {
				type: [Number, String],
				default: "",
			},
			brandCategoryId: {
				type: [Number, String],
				default: "",
			},
			idList: {
				type: Array,
				default: () => {
					return []
				},
			},
		},
		methods: {
			// 打开
			open() {
				this.show = true
				this.params.page = 1
				this.params.page_size = 10
				if (this.brandId) {
					this.params.brand_id = this.brandId
				}
				if (this.brandCategoryId) {
					this.params.brand_category_id = this.brandCategoryId
				}
				this.getSelectList()
			},
			// 获取商品列表
			getSelectList() {
				this.loading = true
				list(this.params).then((res) => {
					this.loading = false
					this.tableData = res.data.list
					this.total = res.data.total
				})
			},
			// 选中商品
			selectionChange(e) {
				this.selectList = e
				this.selectIds = e.map((item) => item.gift_id)
			},
			handleClose() {
				this.selectList = []
				this.selectIds = []
				this.show = false
			},
			handlePage(e) {
				this.params.page = e.page
				this.params.page_size = e.page_size
				this.getSelectList()
			},
			// 删除
			del(index) {
				this.list.splice(index, 1)
				this.idList.splice(index, 1)
				this.$emit("confirm", {
					list: this.list,
					ids: this.idList,
				})
			},
			// 单选
			change(e) {
				this.selectList = [e]
				this.selectIds = [e.gift_id]
				this.confirm()
			},
			// 提交
			confirm() {
				if (this.multiple) {
					// 多选
					this.list.forEach((item, index) => {
						// 查询下标是否已存在
						var index = this.selectList.findIndex(
							(v) => v.gift_id == item.gift_id
						)
						if (index > -1) {
							this.selectList.splice(index, 1)
							this.selectIds.splice(index, 1)
						}
					})
					var params = {
						list: this.list.concat(this.selectList),
						ids: this.idList.concat(this.selectIds),
					}
				} else {
					// 单选
					var params = {
						list: this.selectList,
						ids: this.selectIds,
					}
				}
				this.$emit("confirm", params)
				// 隐藏弹窗
				this.show = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	.btn {
		flex-wrap: wrap;

		.li,
		.upload {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 58px;
			height: 58px;
			border: 1px dotted rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			background: rgba(0, 0, 0, 0.02);
			cursor: pointer;
			margin-top: 10px;

			i {
				font-size: 24px;
			}
		}

		.li {
			position: relative;
			margin: 10px 20px 0 0;

			.images {
				img {
					width: 100%;
					height: 100%;
					display: block;
				}
			}

			i {
				position: absolute;
				z-index: 1;
				font-size: 15px !important;
				width: 20px !important;
				height: 20px !important;
				left: 46px;
				top: -4px;
			}
		}
	}

	.el-dialog {
		min-height: 400px;

		.el-checkbox {
			.el-checkbox__inner {
				border: 1px solid #000;
			}
		}

		.page {
			background-color: #fff;
			padding: 0 40px 20px 0;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			.submit {
				position: absolute;
				bottom: 20px;
				right: 0;
			}
		}
	}
</style>