import service from "@/utils/service.js"

// 赠品列表
export function list (params) {
  return service({
    method: 'get',
    url: '/spread/gift/index',
    params: params
  })
}

// 赠品新增/修改
export function store (data) {
  return service({
    method: 'post',
    url: '/spread/gift/store',
    data
  })
}

// 赠品详情
export function detail (params) {
  return service({
    method: 'get',
    url: '/spread/gift/show',
    params: params
  })
}

// 赠品删除
export function del (data) {
  return service({
    method: 'post',
    url: '/spread/gift/destroy',
    data
  })
}
