import service from "@/utils/service.js"

// 用户列表
export function list(params) {
    return service({
        method: 'get',
        url: '/spread/user/list',
        params: params
    })
}

// 用户选择列表
export function selectList(params) {
    return service({
        method: 'get',
        url: '/spread/user/selectList',
        params: params
    })
}

// 用户详情
export function getDetail(params) {
    return service({
        method: 'get',
        url: '/spread/user/detail',
        params: params
    })
}

// 用户下级列表
export function getSubordinateList(params) {
    return service({
        method: 'get',
        url: '/spread/user/subordinateList',
        params: params
    })
}

// 用户下级星图
export function getDescendantsListTree(params) {
    return service({
        method: 'get',
        url: '/spread/user/descendantsListTree',
        params: params
    })
}

// 用户下级业绩
export function getDescendantsStatistics(params) {
    return service({
        method: 'get',
        url: '/spread/user/descendantsStatistics',
        params: params
    })
}

// 用户修改
export function edit(data) {
    return service({
        method: 'post',
        url: '/spread/user/edit',
        data
    })
}

// 用户积分
export function getIntegral(params) {
    return service({
        method: 'get',
        url: '/spread/user/getIntegral',
        params: params
    })
}

// 用户修改积分
export function editIntegral(data) {
    return service({
        method: 'post',
        url: 'spread/user/editIntegral',
        data
    })
}

// 用户状态切换
export function statusChange(data) {
    return service({
        method: 'post',
        url: '/spread/user/statusChange',
        data
    })
}

// 用户免邮开关
export function freeShippingChange(data) {
    return service({
        method: 'post',
        url: '/spread/user/freeShippingChange',
        data
    })
}

// 是否添加微信开关
export function isAddWxChange(data) {
    return service({
        method: 'post',
        url: '/spread/user/edit',
        data
    })
}

// 用户删除
export function del(data) {
    return service({
        method: 'post',
        url: '/spread/user/del',
        data
    })
}


// 用户导出
export function exportExcel(data = {}) {
    return service({
        method: 'post',
        url: '/spread/user/exportExcel',
        data
    })
}

// 销售业绩
export function userSaleStatistic(params) {
    return service({
        method: 'get',
        url: '/spread/user/saleStatistic',
        params: params
    })
}

// 销售导出
export function saleStatisticExport(params = {}) {
    return service({
        method: 'get',
        url: '/spread/user/saleStatisticExport',
        params: params,
        responseType: 'blob'
    })
}

// 获取导入修改用户信息数据
export function getImportEditInfo(data) {
    return service({
        method: 'post',
        url: '/spread/user/getImportEditInfo',
        data
    })
}

// 导入修改用户信息
export function importEditInfo(data) {
    return service({
        method: 'post',
        url: '/spread/user/importEditInfo',
        data
    })
}

// 用户流水
export function userLogs(params) {
    return service({
        method: 'get',
        url: '/spread/user_fund/logs',
        params: params
    })
}

// 修改余额
export function handle(data = {}) {
    return service({
        method: 'post',
        url: '/spread/user_fund/handle',
        data
    })
}

// 修改上级
export function editParent(data = {}) {
    return service({
        method: 'post',
        url: '/spread/user/editUserParent',
        data
    })
}

// 分配用户给员工
export function editStaff(data = {}) {
    return service({
        method: 'post',
        url: '/spread/user/editUserStaff',
        data
    })
}

// 分配用户给员工
export function editCommissionConfig(data = {}) {
    return service({
        method: 'post',
        url: '/spread/user/editCommissionConfig',
        data
    })
}

// 账户信息
export function infoShow(params) {
    return service({
        method: 'get',
        url: '/spread/user_fund/show',
        params: params
    })
}