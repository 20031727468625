<template>
    <div class="attachment-main">
        <div class="attachment-content u-flex u-col-start u-flex-wrap" v-if="fileType === 'image'">
            <div class="image-li" v-for="(item, index) in fileList" :key="index">
                <el-image fit="cover" class="image" :src="item"
                          referrer-policy="no-referrer|origin|unsafe-url"></el-image>
                <div class="operation u-flex-center u-flex-wrap">
                    <div class="operation-icon el-icon-zoom-in"
                         @click="$refs.imagePreview.show(index, fileList)"></div>
                    <div class="operation-icon el-icon-delete" @click="delFile(index)"></div>
                    <div class="operation-icon el-icon-arrow-left" v-if="number > 1 && fileList.length > 1"
                         @click="move(index, 'left')"
                         title="左移">
                    </div>
                    <div class="operation-icon el-icon-arrow-right" v-if="number > 1 && fileList.length > 1"
                         @click="move(index, 'right')"
                         title="右移">
                    </div>
                </div>
            </div>
            <div class="upload-btn-main" @click="$refs.attachmentSelect.show()">
                <slot name="button">
                    <div class="upload-btn">
                        <i :class="[
                'btn-icon',
                fileType === 'image'
                  ? 'el-icon-picture-outline'
                  : 'el-icon-folder-opened',
              ]"></i>
                    </div>
                </slot>
            </div>
        </div>
        <div class="attachment-content u-flex u-col-start" v-if="fileType === 'application'">
            <div class="attachment">
                <div class="attachment-li" v-for="(item, index) in fileList" :key="index">
                    <div class="link-btn u-flex">
                        <i class="el-icon-document"></i>
                        <a class="link-a" :href="item">查看</a>
                        <i class="el-icon-delete operation-icon" @click="delFile(index)"></i>
                    </div>
                </div>
            </div>
            <div class="upload-btn-main-2" @click="$refs.attachmentSelect.show()">
                <slot name="button">
                    <el-button slot="button" type="success" size="mini">选择文件</el-button>
                </slot>
            </div>
        </div>
        <select-attachment ref="attachmentSelect" :number="number" :fileType="fileType"
                           :confirmFileList="{ fileList: fileList }" @confirm="confirmAttachment">
        </select-attachment>
        <image-preview ref="imagePreview"></image-preview>
    </div>
</template>
<script>
import selectAttachment from "./attachment-select.vue"

export default {
    components: {
        selectAttachment,
    },
    data() {
        return {
            fileList: [],
            previewImg: "",
        }
    },
    props: {
        // 参数名
        name: {
            type: String,
            default: "",
        },
        // 文件类型
        fileType: {
            type: String,
            default: "image",
        },
        // 可选数量
        number: {
            type: Number,
            default: 1,
        },
        // 默认值
        dataList: {
            type: [Array, String],
            default: () => {
                return []
            },
        },
    },
    watch: {
        // 监听默认文件地址
        dataList(newValue) {
            // 当父组件参数改变时，更新子组件参数的值
            this.initData(newValue)
        },
    },
    created() {
    },
    mounted() {
        // 第一次加载赋值
        this.initData(this.dataList)
    },
    methods: {
        // 初始化数据
        initData(dataList) {
            if (dataList) {
                // 是否是数组
                if (Array.isArray(dataList)) {
                    // 是数组
                    if (dataList.length > 0) {
                        // 去除空值后赋值
                        this.fileList = this.$trimSpace(dataList)
                    }
                } else {
                    // 转数组后再赋值
                    this.fileList = dataList.trim().split(",")
                }
            } else {
                this.fileList = []
            }
        },
        // 确定选择图片
        confirmAttachment(e) {
            this.fileList = e.fileList
            this.confirm()
        },
        // 删除文件
        delFile(index) {
            this.fileList.splice(index, 1)
            this.confirm()
        },
        // 左右移动
        move(index, type) {
            if (type === 'left') {
                if (index > 0) {
                    [this.fileList[index], this.fileList[index - 1]] = [this.fileList[index - 1], this.fileList[index]];
                }
            } else if (type === 'right') {
                if (index < this.fileList.length - 1) {
                    [this.fileList[index], this.fileList[index + 1]] = [this.fileList[index + 1], this.fileList[index]];
                }
            }
            this.confirm();
        },
        // 完成提交
        confirm() {
            this.$emit("confirm", {
                name: this.name,
                fileList: this.fileList,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.attachment-main {
    .image-li {
        width: 60px;
        height: 60px;
        margin: 0 10px 10px 0;
        cursor: pointer;
        position: relative;

        .image {
            width: 60px;
            height: 60px;
        }

        .operation {
            display: none !important;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);

            .operation-icon {
                color: #ffffff;
                display: block;
                width: 50%;
                text-align: center;
            }

            .operation-icon:hover {
                color: #00aaff;
                font-weight: bold;
            }
        }
    }

    .image-li:hover .operation {
        display: flex !important;
    }

    .upload-btn-main {
        .upload-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fbfdff;
            border: 1px dashed #c0ccda;
            border-radius: 6px;
            box-sizing: border-box;
            width: 60px;
            height: 60px;
            cursor: pointer;
            line-height: 60px;

            .btn-icon {
                font-size: 26px;
                color: #868686;
            }
        }
    }

    .link-btn {
        height: 28px;
        padding-right: 10px;
        color: #606266;

        .link-a {
            display: block;
            font-size: 13px;
            line-height: 13px;
            height: auto;
            color: #4290f7;
            padding: 0 20px;
        }
    }

    .link-btn:hover {
        cursor: pointer;
    }
}

</style>