<template>
  <div class="select-choose-main u-flex u-col-start">
    <div
      class="select-choose-content u-flex u-flex-wrap"
      v-if="list.length > 0"
    >
      <div class="li" v-for="(item, index) in list" :key="index">
        <el-image
          class="images"
          :src="item[imageName]"
          fit="cover"
          v-if="btnShow"
        ></el-image>
        <div class="operation u-flex-center u-flex-wrap">
          <div
            class="operation-icon el-icon-zoom-in"
            @click="$refs.imagePreview.show(index, previewImageList)"
          ></div>
          <div
            class="operation-icon el-icon-delete"
            @click="$emit('del', index)"
          ></div>
          <div
            class="operation-icon el-icon-arrow-left"
            v-if="isMove && list.length > 1"
            @click="move(index, 'left')"
            title="左移"
          ></div>
          <div
            class="operation-icon el-icon-arrow-right"
            v-if="isMove && list.length > 1"
            @click="move(index, 'right')"
            title="右移"
          ></div>
        </div>
      </div>
    </div>
    <div class="choose-btn" @click="$emit('open')" v-if="btnShow">···</div>
    <image-preview ref="imagePreview"></image-preview>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewImageList: [],
    }
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    imageName: {
      type: String,
      default: "image",
    },
    btnShow: {
      type: Boolean,
      default: true,
    },
    isMove: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    list: {
      handler() {
        this.handlePreviewImage()
      },
    },
  },
  mounted() {
    this.handlePreviewImage()
  },
  methods: {
    handlePreviewImage() {
      this.previewImageList = []
      this.list.forEach((item) => {
        this.previewImageList.push(item[this.imageName])
      })
    },
    // 左右移动
    move(index, type) {
      const list = this.list
      if (type === "left") {
        if (index > 0) {
          ;[list[index], list[index - 1]] = [list[index - 1], list[index]]
        }
      } else if (type === "right") {
        if (index < list.length - 1) {
          ;[list[index], list[index + 1]] = [list[index + 1], list[index]]
        }
      }
      this.$emit("move", list)
    },
  },
}
</script>

<style lang="scss" scoped>
.select-choose-main {
  padding: 5px 0;

  .select-choose-content {
    .li {
      position: relative;
      border-radius: 4px;
      cursor: pointer;
      margin: 0 10px 10px 0;

      .images {
        width: 60px;
        height: 60px;
        display: block;
      }

      .operation {
        display: none !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);

        .operation-icon {
          color: #ffffff;
          display: block;
          width: 50%;
          text-align: center;
        }

        .operation-icon:hover {
          color: #00aaff;
          font-weight: bold;
        }
      }
    }

    .li:hover .operation {
      display: flex !important;
    }
  }

  .choose-btn {
    width: 58px;
    height: 58px;
    border: 1px dotted rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    line-height: 58px;
    font-size: 20px;
  }
}
</style>
