<template>
	<div class="u-flex-column u-col-center">
		<el-upload action="/" :auto-upload="false" :show-file-list="false" :on-change="getExcel">
			<el-button type="primary" size="mini">选择文件</el-button>
		</el-upload>
	</div>
</template>
<script>
	import * as XLSX from "xlsx"
	export default {
		name: "read-excel",
		data() {
			return {}
		},
		props: {
		},
		methods: {
			// 上传验证
			getExcel(file) {
				const type = file.name.split(".");
				const types = type[type.length - 1];
				const fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
					item => item === types
				);
				if (!fileType) {
					this.$errorMsg("格式错误！请重新选择")
					return;
				}
				this.analysis(file).then(tabJson => {
					if (tabJson && tabJson.length > 0) {
						this.$emit('complete', tabJson)
					}
				});
			},
			// 解析excel
			analysis(file) {
				return new Promise(function(resolve, reject) {
					const fileReader = new FileReader();
					fileReader.onload = function(e) {
						const data = e.target.result;
						const workbook = XLSX.read(data, {
							type: "binary"
						});
						// const result = [];
						// workbook.SheetNames.forEach(sheetName => {
						// 	result.push({
						// 		sheetName: sheetName,
						// 		sheet: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
						// 	});
						// });
						const worksheet = workbook.Sheets[workbook.SheetNames[0]];
						const result = XLSX.utils.sheet_to_json(worksheet);
						resolve(result);
					};
					fileReader.readAsBinaryString(file.raw);
				});
			},
		},
	}
</script>
<style lang="scss">
</style>