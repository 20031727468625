import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
	// 共享状态（即变量）
	state: {
		// 缓存组件页面
		catch_components: [],
		// 当前选中的菜单 - 默认选择首页
		activePath: '/',
		// 菜单项 - 默认包含首页
		navList: [{
			path: '/',
			label: '首页',
			name: '/'
		}, ]
	},
	// 更改vuex的store中状态的唯一方法 - 同步操作
	mutations: {
		// 跳转页面执行
		selectMenu(state, submenu) {

			// 首页就是 welcome   也就是 /
			if (submenu.name === 'welcome' || submenu.name === '/') {
				submenu.name = '/'
				submenu.path = '/'
			}

			// 当前选中菜单
			var activePath = submenu.path

			// 历史已选中菜单列表
			var oldnavList = state.navList

			// 将菜单信息添加到navList - 添加时判断是否已有该标签
			var result = oldnavList.some(item => {
				if (item.name === activePath) {
					return true
				}
			})

			// 如果不包含该对象，则添加
			if (!result) {
				oldnavList.push({
					path: submenu.path,
					name: submenu.path,
					label: submenu.label
				})
			}
			// 重新赋值
			state.activePath = activePath
			state.navList = oldnavList
		},
		// 添加keepalive缓存
		addKeepAliveCache(state, val) {
			// 如果是首页，不缓存
			if (val === 'welcome' || val === '/') {
				return
			}
			// 添加时判断，如果该组件已存在，则不添加
			if (state.catch_components.indexOf(val) === -1) {
				// 不存在，缓存页面
				state.catch_components.push(val)
			}
		},
		// 删除keepalive缓存
		removeKeepAliveCache(state, val) {
			let cache = state.catch_components
			for (let i = 0; i < cache.length; i++) {
				if (cache[i] === val) {
					cache.splice(i, 1);
				}
			}
			state.catch_components = cache
		},
		//关闭菜单
		closeTab(state, val) {
			// 重新赋值
			state.activePath = val.activePath
			state.navList = val.navList
		},
		// 点击标签选择菜单
		navChange(state, val) {
			state.activePath = val
		}
	},
	// 和mutations类似，异步操作
	Action: {

	}
})

export default store