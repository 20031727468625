import service from "@/utils/service.js"

// 秒杀活动列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/seckillActivity/list',
		params: params
	})
}


// 秒杀活动新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/seckillActivity/add',
		data
	})
}

// 秒杀活动详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/seckillActivity/detail',
		params: params
	})
}

// 秒杀活动修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/seckillActivity/edit',
		data
	})
}


// 秒杀活动状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/seckillActivity/statusChange',
		data
	})
}

// 秒杀活动删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/seckillActivity/del',
		data
	})
}

// 秒杀活动是否展示到首恶
export function isIndexChange (data) {
	return service({
		method: 'post',
		url: '/spread/seckillActivity/isIndexChange',
		data
	})
}
