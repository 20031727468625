import service from "@/utils/service.js"

// 商品品牌列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/productBrand/list',
		params: params
	})
}


// 商品品牌新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/productBrand/add',
		data
	})
}

// 商品品牌详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/productBrand/detail',
		params: params
	})
}

// 商品品牌选择列表
export function selectList (params) {
	return service({
		method: 'get',
		url: '/spread/productBrand/selectList',
		params: params
	})
}

// 商品品牌修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/productBrand/edit',
		data
	})
}

// 商品是否首页推荐
export function isIndexChange (data) {
	return service({
		method: 'post',
		url: '/spread/productBrand/isIndexChange',
		data
	})
}

// 商品品牌状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/productBrand/statusChange',
		data
	})
}

// 商品品牌删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/productBrand/del',
		data
	})
}

// 列表拖拽排序
export function sorts (data) {
	return service({
		method: 'post',
		url: '/spread/productBrand/setSorts',
		data
	})
}
