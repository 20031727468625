<template>
  <div>
    <div class="table-tools u-flex-between">
      <div class="data-top-left u-flex">
        <!-- 读取excel文件 -->
        <read-excel @complete="readExcelComplete"></read-excel>
        <!-- 验证数据 -->
        <el-button
          class="ml-10"
          type="success"
          size="mini"
          :loading="!verifyStatus"
          v-if="list.length > 0"
          @click="$emit('verify')"
          >{{ verifyStatus ? "验证数据" : "验证中" }}</el-button
        >
        <!-- 确认导入 -->
        <div style="margin: 0 10px">
          <el-button
            type="success"
            size="mini"
            :disabled="!sureImport"
            :loading="!isSubmit"
            v-if="step == 2"
            @click="$emit('import')"
            >{{
              sureImport ? (isSubmit ? "确认导入" : "导入中") : "不可导入"
            }}</el-button
          >
        </div>
        <el-button
          type="warning"
          size="mini"
          :disabled="dataListLength == 0"
          v-if="step == 2"
          @click="$emit('onekeyChange')"
          >选中全部异常</el-button
        >
        <el-button
          type="danger"
          size="mini"
          :disabled="disabled"
          v-if="step == 2"
          @click="$emit('delChange')"
          >删除</el-button
        >
        <!-- 异常数据 -->
        <div class="err-tips label-green" v-if="step == 2 && sureImport">
          可导入，请点击导入!
        </div>
        <div class="err-tips label-red" v-if="step == 2 && !sureImport">
          提示：请修改“异常信息”后，重新上传修改信息
        </div>
      </div>
      <div class="excel-template u-flex-center">
        <a class="template-a" :href="templateUrl">下载模板</a>
      </div>
    </div>

    <div class="msg-main" v-if="step > 1">
      <span class="label-black">注意：</span>
      <span class="label-blue">蓝色为修改项</span>，
      <span class="label-green">绿色为不修改项</span>，
      <span class="label-red">红色为异常项</span>。
    </div>
  </div>
</template>

<script>
export default {
  name: "table-tools",
  components: {},
  data() {
    return {}
  },
  // 定义传入的参数
  props: {
    templateUrl: {
      type: String,
      default: () => {
        return ""
      },
    },
    header: {
      type: Array,
      default: () => {
        return []
      },
    },
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    step: {
      type: Number,
      default: () => {
        return 0
      },
    },
    sureImport: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    verifyStatus: {
      type: Boolean,
      default: () => {
        return true
      },
    },
    isSubmit: {
      type: Boolean,
      default: () => {
        return true
      },
    },
    disabled: {
      type: Boolean,
      default: () => {
        return true
      },
    },
    dataListLength: {
      type: Number,
      default: () => {
        return 0
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    readExcelComplete(e) {
      var xlsx = e
      var dataList = []
      var length = 0
      this.header.forEach((item, index) => {
        var currData = {}
        xlsx.forEach((v, k) => {
          if (xlsx.length > dataList.length) {
            dataList.push({})
          }
          for (var curr in v) {
            if (item.name == curr) {
              dataList[k][item.field] = v[curr]
            }
          }
          if (dataList[k][item.field] == undefined) {
            dataList[k][item.field] = ""
          }
        })
      })
      this.$emit("readFile", dataList)
    },
  },
}
</script>
<style lang="scss">
.ml-10 {
  margin-left: 10px;
}

.excel-template {
  height: 28px;

  .template-a {
    display: block;
    font-size: 13px;
    line-height: 13px;
    height: auto;
    color: #4290f7;
    padding: 0 20px;
  }
}

.err-tips {
  padding-left: 10px;
}
.msg-main {
  padding: 10px 0;
}
</style>
