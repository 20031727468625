import service from "@/utils/service.js"

// 列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/institution/list',
		params: params
	})
}

// 选择列表
export function selectList (params) {
	return service({
		method: 'get',
		url: '/spread/institution/selectList',
		params: params
	})
}

// 详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/institution/detail',
		params: params
	})
}

// 添加
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/institution/add',
		data
	})
}

// 修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/institution/edit',
		data
	})
}

// 状态开关
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/institution/statusChange',
		data
	})
}

// 删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/institution/del',
		data
	})
}

// 列表拖拽排序
export function sorts (data) {
	return service({
		method: 'post',
		url: '/spread/institution/setSorts',
		data
	})
}