export default {
	install(Vue) {
		/**
		 * @desc 文件域名拼接
		 * @param e 状态
		 */
		Vue.prototype.$setStorage = function(key, value) {
			return localStorage.setItem(key, value)
		}
		/**
		 * @desc 文件域名拼接
		 * @param e 状态
		 */
		Vue.prototype.$getStorage = function(key, value) {
			return localStorage.getItem(key)
		}
		/**
		 * @desc 文件域名拼接
		 * @param e 状态
		 */
		Vue.prototype.$removeStorage = function(key, value) {
			return localStorage.removeItem(key)
		}
	}
}