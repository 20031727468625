import Vue from 'vue'

// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading;
let needLoadingRequestCount = 0 // 声明一个对象用于存储请求个数
// 公共js文件
export default {
	install(Vue) {
		Vue.prototype.$startLoading = function(target, msg, background) {
			setTimeout(() => {
				loading = Vue.prototype.$loading({
					lock: true,
					text: msg,
					spinner: 'el-icon-loading',
					background: background,
					target: target ? document.querySelector(target) : 'body' // 设置加载动画区域
				})
			}, 1)
		}
		Vue.prototype.$endLoading = function() {
			loading.close()
		}
		Vue.prototype.$showLoading = function(target = '', msg = '努力加载中...', background = 'rgba(255,255,255,0.1)') {
			if (needLoadingRequestCount === 0) {
				this.$startLoading(target, msg, background)
			}
			needLoadingRequestCount++
		}
		Vue.prototype.$hideLoading = function() {
			if (needLoadingRequestCount <= 0) return
			needLoadingRequestCount--
			if (needLoadingRequestCount === 0) {
				this.$endLoading()
			}
		}
	}
}