import axios from 'axios'
// import {
//   Message
// } from 'element-ui'
// import loading from "@/utils/loading.js";
// import {
// 	getStorage,
// 	removeStorage
// } from '@/utils/storage.js'
import Vue from 'vue'
// 使用ES模块的方式
import {
	errorMsg,
} from '@/utils/message.js'

// 公共方法
import storage from './storage.js'
const BASEURL = process.env.VUE_APP_BASE_URL
// 上传域名https://api.mtjtest.cdydsy.com/ https://api.mtj.cdydsy.com/
export const uploadUrl = `${BASEURL}spread/attachment/add`

const service = axios.create({
	baseURL: BASEURL,
	timeout: 1000000
})
// 添加请求拦截器
service.interceptors.request.use(config => {
	// 获取并设置token
	config.headers['Authorization'] = `Bearer ${Vue.prototype.$getStorage('token')}`
	config.headers['Content-Type'] = 'application/json'
	return config
}, (error) => {
	return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use((response) => {// 成功
	if (response.data.code == 404) {
		Vue.prototype.$errorMsg('系统异常，请稍后重试')
		return Promise.reject(errorRes)
	} else if (response.data.code) {
		return response.data
	} else if (response.data.code == undefined) {
		return response
	} else {
		Vue.prototype.$errorMsg('系统异常，请稍后重试')
		return Promise.reject(errorRes)
	}
}, (errorRes) => { // 失败
	var res = errorRes.response.data
	Vue.prototype.$errorMsg(res.msg || '系统异常，请稍后重试')
	if (res.code == 500) { // 失败
		return Promise.reject(errorRes)
	} else if (res.code == 201 || res.code == 203 || res.code == 403) { // 登录失效
		Vue.prototype.$removeStorage('token')
		Vue.prototype.$removeStorage('adminInfo')
		Vue.prototype.$removeStorage('systemConfig')
		setTimeout(() => {
			window.location.replace("/#/login")
		}, 1000)
	}
})

export default service