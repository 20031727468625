<template>
  <el-row :gutter="24" class="el-row-c">
    <el-form
      ref="formRef"
      :model="params"
      :inline="true"
      @keyup.enter.native="search"
    >
      <el-form-item
        v-for="(item, index) in searchProps.formList"
        :key="index"
        :label="item.label"
      >
        <!-- 输入框 -->
        <el-input
          v-if="item.type == 'input'"
          :type="item.inputType || ''"
          size="mini"
          clearable
          v-model="params[item.paramsName]"
          :placeholder="item.placeholder || ''"
          @clear="change"
          @blur="change"
          style="width: 100%"
        >
        </el-input>
        <div class="input-between u-flex" v-if="item.type == 'input_between'">
          <!-- 输入框 -->
          <el-input
            :type="item.inputType || ''"
            size="mini"
            clearable
            v-model="params[item.paramsName][0]"
            :placeholder="item.placeholder[0] || ''"
            @clear="change"
            @blur="change"
            style="width: 40%"
          >
          </el-input>
          <div class="input-between-symbol">-</div>
          <!-- 输入框 -->
          <el-input
            :type="item.inputType || ''"
            size="mini"
            clearable
            v-model="params[item.paramsName][1]"
            :placeholder="item.placeholder[1] || ''"
            @clear="change"
            @blur="change"
            style="width: 40%"
          >
          </el-input>
        </div>
        <!-- 时间选择 -->
        <!-- :format="item.timeProps.format || 'yyyy-MM-dd'" -->
        <el-date-picker
          v-if="item.type == 'date-picker'"
          size="mini"
          v-model="params[item.paramsName]"
          clearable
          :type="item.timeProps.timeType"
          :picker-options="item.timeProps.pickerOptions"
          range-separator="至"
          :start-placeholder="item.timeProps.startPlaceholder || ''"
          :end-placeholder="item.timeProps.endPlaceholder || ''"
          :value-format="item.timeProps.valueFormat || 'yyyy-MM-dd hh:mm:ss'"
          @change="change"
        >
        </el-date-picker>
        <el-date-picker
          :type="item.monthProps.timeType"
          placeholder="选择日期"
          v-if="item.type == 'month-picker'"
          size="mini"
          v-model="params[item.paramsName]"
          :value-format="item.monthProps.valueFormat || 'yyyy-MM'"
          @change="change"
        >
        </el-date-picker>
        <!-- 下拉框 -->
        <el-select
          v-if="item.type == 'select'"
          size="mini"
          clearable
          v-model="params[item.paramsName]"
          :placeholder="item.placeholder || ''"
          @change="change"
        >
          <el-option
            v-for="(selectValue, selectIndex) in item.selectList"
            :key="selectIndex"
            :label="selectValue.label || selectValue.name"
            :value="selectValue.value || selectValue.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="searchProps.search"
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="search"
        >
        </el-button>
        <el-button v-if="searchProps.reset" size="mini" @click="reset"
          >重置
        </el-button>
      </el-form-item>
    </el-form>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      params: {},
    };
  },
  props: {
    // 搜索配置信息
    searchProps: {
      type: [Object, Array],
      default: {},
    },
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化搜索配置信息
    init() {
      let form = {};
      this.searchProps.formList.forEach((item) => {
        // 是否有默认值
        if (item.default !== undefined) {
          // 有默认值
          form[item.paramsName] = item.default;
        } else {
          if (item.type == "input_between") {
            form[item.paramsName] = ["", ""];
          } else {
            // 无默认值
            form[item.paramsName] = "";
          }
        }
      });
      this.params = form;
    },
    // 搜索
    search() {
      this.$emit("search");
    },
    // 值改变
    change(e) {
      this.$emit("change", this.params);
    },
    // 重置
    reset(isSearch = true) {
      // 初始化数据
      this.init();
      this.$emit("change", this.params);
      if (isSearch) {
        this.$emit("search");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  padding-bottom: 20px;
}

.el-form-item {
  margin-bottom: 0 !important;
}

.input-between-symbol {
  width: 30px;
  text-align: center;
  font-size: 14px;
}
</style>
