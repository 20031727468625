<template>
    <div>
        <el-button type="primary" size="mini" @click="show = true">采集</el-button>
        <el-dialog
            title="商品采集"
            :visible.sync="show"
            :before-close="handleClose"
            width="80%"
            top="2vh"
            :close-on-click-modal="false"
            :append-to-body="true"
        >
            <div class="search">
                <el-form :inline="true">
                    <el-form-item label="链接地址" label-width="120px">
                        <el-input
                            size="mini"
                            v-model="params.url"
                            placeholder="商品链接"
                            style="width: 500px"
                        ></el-input>
                        <div class="form-tip">
                            提示：地址（支持淘宝、天猫、京东、苏宁、1688）
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            size="mini"
                            :loading="isLoading"
                            @click="search"
                        >{{ isLoading ? "查询中" : "查询" }}
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
            <div class="main u-flex u-col-start" v-if="!loading">
                <div class="main-left">
                    <el-form label-width="120px">
                        <el-form-item label="商品名称">
                            <div class="image-list u-flex">
                                <div class="image-li" v-if="info.name !== ''">
                                    {{ info.name }}
                                    <div class="del-icon-2 el-icon-close" @click="del('name')"></div>
                                </div>
                                <div class="empty" v-else>暂无数据</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="封面图">
                            <div class="image-list u-flex">
                                <div class="image-li" v-if="info.cover_image !== ''">
                                    <el-image
                                        :src="info.cover_image"
                                        class="detail-image"
                                        :preview-src-list="[info.cover_image]"
                                        fit="contain"
                                    ></el-image>
                                    <div class="del-icon el-icon-close" @click="del('cover_image')"></div>
                                </div>
                                <div class="empty" v-else>暂无数据</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="详情轮播图">
                            <div class="image-list u-flex u-flex-wrap">
                                <div
                                    class="image-li"
                                    v-for="(item, index) in info.detail_images"
                                >
                                    <el-image
                                        class="detail-image"
                                        :src="item"
                                        :preview-src-list="info.detail_images"
                                        fit="contain"
                                    >
                                    </el-image>
                                    <div class="del-icon el-icon-close" @click="del('detail_images', index)"></div>
                                </div>
                                <div class="empty" v-if="info.detail_images.length === 0">
                                    暂无数据
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="详情介绍图">
                            <div class="image-list u-flex u-flex-wrap">
                                <div
                                    class="image-li"
                                    v-for="(item, index) in info.introduce_images"
                                >
                                    <el-image
                                        class="detail-image"
                                        :src="item"
                                        :preview-src-list="info.introduce_images"
                                        fit="contain"
                                    >
                                    </el-image>
                                    <div class="del-icon el-icon-close" @click="del('introduce_images', index)"></div>
                                </div>
                                <div class="empty" v-if="info.introduce_images.length === 0">
                                    暂无数据
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="规格图片">
                            <div class="image-list u-flex u-flex-wrap">
                                <div
                                    class="image-li"
                                    v-for="(item, index) in info.spec_images"
                                >
                                    <el-image
                                        class="detail-image"
                                        :src="item"
                                        :preview-src-list="info.spec_images"
                                        fit="contain"
                                    >
                                    </el-image>
                                    <div class="del-icon el-icon-close" @click="del('spec_images', index)"></div>
                                </div>
                                <div class="empty" v-if="info.spec_images.length === 0">
                                    暂无数据
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="main-right">
                    <el-form label-width="120px">
                        <el-form-item label="商品名称">
                            <div class="image-list u-flex">
                                <div class="image-li" v-if="moreInfo.name !== ''">
                                    {{ moreInfo.name }}
                                    <el-dropdown @command="moreChange($event,'name')">
                                    <span class="el-dropdown-link">添加至<i
                                        class="el-icon-arrow-down el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="name">名称</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="封面图">
                            <div class="image-list u-flex">
                                <div class="image-li" v-if="moreInfo.cover_image !== ''">
                                    <el-image
                                        :src="moreInfo.cover_image"
                                        class="detail-image"
                                        :preview-src-list="[moreInfo.cover_image]"
                                        fit="contain"
                                    ></el-image>
                                    <el-dropdown @command="moreChange($event,'cover_image')">
                                    <span class="el-dropdown-link">添加至<i
                                        class="el-icon-arrow-down el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="cover_image">封面图</el-dropdown-item>
                                            <el-dropdown-item command="detail_images">详情图</el-dropdown-item>
                                            <el-dropdown-item command="introduce_images">介绍图</el-dropdown-item>
                                            <el-dropdown-item command="spec_images">规格图</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div class="empty" v-else>暂无数据</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="详情轮播图">
                            <div class="image-list u-flex u-flex-wrap">
                                <div
                                    class="image-li"
                                    v-for="(item, index) in moreInfo.detail_images"
                                >
                                    <el-image
                                        class="detail-image"
                                        :src="item"
                                        :preview-src-list="moreInfo.detail_images"
                                        fit="contain"
                                    >
                                    </el-image>
                                    <el-dropdown @command="moreChange($event,'detail_images',index)">
                                    <span class="el-dropdown-link">添加至<i
                                        class="el-icon-arrow-down el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="cover_image">封面图</el-dropdown-item>
                                            <el-dropdown-item command="detail_images">详情图</el-dropdown-item>
                                            <el-dropdown-item command="introduce_images">介绍图</el-dropdown-item>
                                            <el-dropdown-item command="spec_images">规格图</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div class="empty" v-if="moreInfo.detail_images.length === 0">
                                    暂无数据
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="详情介绍图">
                            <div class="image-list u-flex u-flex-wrap">
                                <div
                                    class="image-li"
                                    v-for="(item, index) in moreInfo.introduce_images"
                                >
                                    <el-image
                                        class="detail-image"
                                        :src="item"
                                        :preview-src-list="moreInfo.introduce_images"
                                        fit="contain"
                                    >
                                    </el-image>
                                    <el-dropdown @command="moreChange($event,'introduce_images',index)">
                                    <span class="el-dropdown-link">添加至<i
                                        class="el-icon-arrow-down el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="cover_image">封面图</el-dropdown-item>
                                            <el-dropdown-item command="detail_images">详情图</el-dropdown-item>
                                            <el-dropdown-item command="introduce_images">介绍图</el-dropdown-item>
                                            <el-dropdown-item command="spec_images">规格图</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div class="empty" v-if="moreInfo.introduce_images.length === 0">
                                    暂无数据
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="规格图片">
                            <div class="image-list u-flex u-flex-wrap">
                                <div
                                    class="image-li"
                                    v-for="(item, index) in moreInfo.spec_images"
                                >
                                    <el-image
                                        class="detail-image"
                                        :src="item"
                                        :preview-src-list="moreInfo.spec_images"
                                        fit="contain"
                                    >
                                    </el-image>
                                    <el-dropdown @command="moreChange($event,'spec_images',index)">
                                    <span class="el-dropdown-link">添加至<i
                                        class="el-icon-arrow-down el-icon--right"></i></span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="cover_image">封面图</el-dropdown-item>
                                            <el-dropdown-item command="detail_images">详情图</el-dropdown-item>
                                            <el-dropdown-item command="introduce_images">介绍图</el-dropdown-item>
                                            <el-dropdown-item command="spec_images">规格图</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div class="empty" v-if="moreInfo.spec_images.length === 0">
                                    暂无数据
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="submit u-flex-end">
                <el-button size="mini" type="primary" @click="confirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {productAcquisition} from "@/api/product-acquisition.js"

export default {
    components: {},
    data() {
        return {
            show: false,
            loading: true, // true-未加载 false-已加载
            isLoading: false,
            params: {
                url: "",
            },
            info: {
                name: "",
                cover_image: "",
                detail_images: [],
                introduce_images: [],
                spec_images: [],
            },
            moreInfo: [],
        }
    },
    props: {},
    methods: {
        // 获取用户列表
        search() {
            this.isLoading = true
            productAcquisition(this.params)
                .then((res) => {
                    if (res.code === 200) {
                        this.loading = false
                        this.isLoading = false
                        var info = res.data.goodsInfo;
                        this.info = this.$deepClone(info);
                        this.moreInfo = this.$deepClone(info);
                    }
                })
                .catch((ex) => {
                    this.isLoading = false
                })
        },
        moreChange(name, moreName, index = 0) {
            if (name === 'name') {
                this.info[name] = this.moreInfo[moreName];
            } else if (name === 'cover_image') {
                if (moreName === 'cover_image') {
                    this.info[name] = this.moreInfo[moreName];
                } else {
                    this.info[name] = this.moreInfo[moreName][index];
                }
            } else {
                if (moreName === 'cover_image') {
                    this.info[name].push(this.moreInfo[moreName]);
                } else {
                    this.info[name].push(this.moreInfo[moreName][index]);
                }
            }
        },
        // 删除
        del(name, index = 0) {
            if (name === 'name' || name === 'cover_image') {
                this.info[name] = ""
            } else {
                this.info[name].splice(index, 1)
            }
        },
        handleClose() {
            // this.params.url = ""
            // this.params.info = {
            //     name: "",
            //     cover_image: "",
            //     detail_images: [],
            //     introduce_images: [],
            //     spec_images: [],
            // }
            // this.loading = true
            // this.isLoading = false
            this.show = false
        },
        // 提交
        confirm() {
            this.$emit("confirm", this.info)
            // 隐藏弹窗
            this.handleClose()
        },
    },
}
</script>

<style lang="scss" scoped>
.image-list {
    .image-li {
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;

        .detail-image {
            width: 140px !important;
            height: 140px !important;
            display: block;
            margin-right: 0px;
        }

        .del-icon {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 300;
        }

        .del-icon,
        .del-icon-2 {
            font-size: 18px;
            padding: 2px;
            color: white;
            background-color: red;
            border-radius: 50px;
        }

        .del-icon:hover {
            cursor: pointer;
        }
    }
}

.main-left, .main-right {
    width: 50%;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}
</style>
