<template>
	<div>
		<text-button :list="list" @del="del($event)" @open="open()"></text-button>
		<el-dialog title="员工选择" :visible.sync="show" :before-close="handleClose" width="60%"
			:close-on-click-modal="false" :append-to-body="true">
			<div class="search">
				<el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="姓名">
                        <el-input size="mini" v-model="params.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
					<el-form-item>
						<el-button type="primary" size="mini" @click="getSelectList">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table height="400" v-loading="loading" :data="tableData" tooltip-effect="dark" highlight-current-row
				style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" align="center" v-if="multiple">
				</el-table-column>
				<el-table-column property="id" label="ID" min-width="120" align="center">
				</el-table-column>
				<el-table-column property="name" label="姓名" min-width="200" align="center">
					<template slot-scope="scope">
						<div>{{ scope.row.name }}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="100" align="center" v-if="!multiple">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="change(scope.row)">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="page u-flex-between">
				<pagination :page="params.page" :pageSize="10" :total="total" :pageSizeList="[10, 50, 100, 200, 500]"
					@pagination="handlePage" />
				<div class="submit" v-if="multiple">
					<el-button size="mini" type="primary" @click="confirm">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list as selectList
	} from "@/api/staff.js"
	import textButton from "./select-choose-button/text-button.vue"
	export default {
		components: {
			textButton,
		},
		data() {
			return {
				show: false,
				loading: true,
				params: {
                    name: "",
					page: 1,
					page_size: 10,
				},
				total: 0,
				tableData: [],
				selectList: [],
				selectIds: [],
			}
		},
		props: {
			multiple: {
				type: Boolean, // true-多选 false-单选
				default: false,
			},
			list: {
				type: Array,
				default: () => {
					return []
				},
			},
			idList: {
				type: Array,
				default: () => {
					return []
				},
			},
		},
		methods: {
			// 打开
			open() {
				this.show = true;
				this.params.page = 1;
				this.params.page_size = 10;
				this.getSelectList();
			},
			getSelectList() {
				this.loading = true;
				selectList(this.params).then((res) => {
					this.loading = false;
					this.tableData = res.data.data
					this.total = res.data.total
				})
			},
			// 选择
			selectionChange(e) {
				this.selectList = e
				this.selectIds = e.map((item) => item.id)
			},
			handleClose() {
				this.selectList = []
				this.selectIds = []
				this.show = false
			},
			handlePage(e) {
				this.params.page = e.page
				this.params.page_size = e.page_size
				this.getSelectList()
			},
			// 删除
			del(index) {
				this.list.splice(index, 1)
				this.idList.splice(index, 1)
				this.$emit("confirm", {
					list: this.list,
					ids: this.idList,
				})
			},
			// 单选
			change(e) {
				this.selectList = [e]
				this.selectIds = [e.id]
				this.confirm()
			},
			clear(key) {
				this.selectList.splice(key, 1)
				this.selectIds.splice(key, 1)
				this.confirm();
			},
			resetData() {
                this.params.name = '';
				this.params.page = 1;
				this.params.page_size = 10;
				this.selectList = [];
				this.selectIds = [];
			},
			// 提交
			confirm() {
				if (this.multiple) {
					// 多选
					this.list.forEach((item, index) => {
						// 查询下标是否已存在
						var index = this.selectList.findIndex((v) => v.id == item.id)
						if (index > -1) {
							this.selectList.splice(index, 1)
							this.selectIds.splice(index, 1)
						}
					})
					var params = {
						list: this.list.concat(this.selectList),
						ids: this.idList.concat(this.selectIds),
					}
				} else {
					// 单选
					var params = {
						list: this.selectList,
						ids: this.selectIds,
					}
				}
				this.$emit("confirm", params)
				// 隐藏弹窗
				this.show = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	.select-choose-main {
		min-height: 40px;

		.select-choose-content {
			margin-right: 10px;
		}
	}
</style>