<template>
	<div class="tools u-flex-between">
		<div class="tools-li">
			<el-button type="info" size="mini" icon="el-icon-refresh" v-if="refresh" @click="$emit('refresh')">
			</el-button>
			<el-button type="primary" size="mini" icon="el-icon-plus" v-if="add" @click="$emit('add')">添加</el-button>
			<el-button type="danger" size="mini" icon="el-icon-delete" v-if="batchDel" @click="$emit('batchDel')">删除
			</el-button>
		</div>
		<div class="tools-li">
			<el-button size="mini" icon="el-icon-search" v-if="search" @click="$emit('openSearch')">
			</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		// 定义传入的参数
		props: {
			// 是否开启刷新功能
			refresh: {
				type: Boolean,
				default: true,
			},
			// 是否开启添加功能
			add: {
				type: Boolean,
				default: true,
			},
			// 是否开启批量删除功能
			batchDel: {
				type: Boolean,
				default: true,
			},
			// 是否开启搜索功能
			search: {
				type: Boolean,
				default: true,
			}
		},
		created() {},
		methods: {}
	}
</script>

<style lang="scss">
</style>
