import service from "@/utils/service.js"

// 积分商品列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/integralProduct/list',
		params: params
	})
}

// 积分商品规格选择列表
export function selectList (params) {
	return service({
		method: 'get',
		url: '/spread/product/specSelectList',
		params: params
	})
}

// 积分商品新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/integralProduct/add',
		data
	})
}

// 积分商品详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/integralProduct/detail',
		params: params
	})
}

// 积分商品修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/integralProduct/edit',
		data
	})
}

// 积分商品状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/integralProduct/statusChange',
		data
	})
}

// 积分商品删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/integralProduct/del',
		data
	})
}