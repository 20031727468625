<template>
	<div>
		<el-tag v-for="(item,index) in tagsInput" :key="index" size="medium" closable :disable-transitions="false"
			@close="tagClose(index)">
			{{item}}
		</el-tag>
		<el-input class="input" ref="tagsInput" v-show="show" v-model="value" size="mini" clearable
			@keyup.enter.native="inputConfirm()" @blur="inputConfirm()">
		</el-input>
		<el-button v-show="!show" size="mini" @click="showInput()">+ 添加
		</el-button>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				show: false,
				value: '',
				tagsInput: [],
			};
		},
		props: {
			name: {
				type: String,
				default: '',
			},
			list: {
				type: [Array, Object],
				default: [],
			}
		},
		created() {
			this.tagsInput = this.list;
		},
		methods: {
			// 显示输入框
			showInput(e) {
				let that = this;
				that.show = true;
				this.$nextTick(() => {
					this.$refs.tagsInput.focus();
				});
			},
			// 输入框确认
			inputConfirm(e) {
				let value = this.value;
				if (this.tagsInput.indexOf(value) > -1) {
					this.$errorMsg('当前选项已存在');
					return;
				}
				if (value) {
					this.tagsInput.push(value);
				}
				this.show = false;
				this.value = '';
				this.change();
			},
			// 移除
			tagClose(index) {
				this.tagsInput.splice(index, 1);
				this.change();
			},
			// 触发
			change() {
				this.$emit('change', {
					name: this.name,
					value: this.tagsInput,
				})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.el-tag {
		margin-right: 10px;
	}

	.input {
		width: 90px;
		vertical-align: bottom;
	}
</style>