<template>
	<div class="pagination u-flex u-flex-center">
		<el-pagination :current-page.sync="currentPage" :page-size="currentPageSize" :page-sizes="pageSizeList"
			:total="total" :layout="layout" :background="background" @current-change="pageChange"
			@size-change="sizeChange">
		</el-pagination>
	</div>
</template>
<script>
	export default {
		name: "pagination",
		data() {
			return {
				// 当前页
				currentPage: 1,
				currentPageSize: 10,
			}
		},
		props: {
			// 布局信息
			layout: {
				type: String,
				default: "total, sizes, prev, pager, next, jumper",
			},
			// 当前页
			page: {
				type: Number,
				default: 1,
			},
			// 每页数量
			page_size: {
				type: Number,
				default: 10,
			},
			// 总数
			total: {
				required: true,
				type: Number,
				default: 0,
			},
			// 可选每页数量
			pageSizeList: {
				type: Array,
				default () {
					return [10, 20, 50, 100, 500]
				},
			},
			// 是否使用背景色
			background: {
				type: Boolean,
				default: true,
			},
		},
		created() {
			this.currentPage = this.page
			this.currentPageSize = this.page_size
		},
		methods: {
			// 分页切换
			pageChange(e) {
				this.$emit("pagination", {
					page: this.currentPage,
					page_size: this.currentPageSize,
				})
			},
			// 每页数量切换
			sizeChange(e) {
				this.currentPageSize = e
				this.pageChange(1)
			},
		},
	}
</script>
<style lang="scss">
	.pagination {
		padding-top: 20px;
	}
</style>