import service from "@/utils/service.js"

// 限时秒杀列表
export function list (params) {
  return service({
    method: 'get',
    url: '/spread/seckillProduct/index',
    params: params
  })
}

// 选择商品列表
export function selectList (params) {
  return service({
    method: 'get',
    url: '/spread/seckillProduct/selectList',
    params: params
  })
}
// 限时秒杀新增
export function add (data) {
  return service({
    method: 'post',
    url: '/spread/seckillProduct/add',
    data
  })
}

// 限时秒杀详情
export function getDetail (params) {
  return service({
    method: 'get',
    url: '/spread/seckillProduct/detail',
    params: params
  })
}

// 限时秒杀修改
export function edit (data) {
  return service({
    method: 'post',
    url: '/spread/seckillProduct/edit',
    data
  })
}

// 限时秒杀状态切换
export function statusChange (data) {
  return service({
    method: 'post',
    url: '/spread/seckillProduct/statusChange',
    data
  })
}

// 限时秒杀删除
export function del (data) {
  return service({
    method: 'post',
    url: '/spread/seckillProduct/del',
    data
  })
}
