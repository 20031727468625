// 自定义组件全局引入
import attachmentSelectBtn from './common/attachment-select-btn.vue'
import attachmentSelect from './common/attachment-select.vue'
import upload from './common/upload.vue'
import imagePreview from './common/image-preview.vue'
import pagination from './common/pagination.vue'
import selectPage from './form/select-page.vue'
import listSearch from './list/search/search.vue'
import listTools from './list/tools/tools.vue'
import tagsInput from './common/tags-input.vue'
import loadPage from './common/load-page.vue'
import selectProduct from '@/components/select-list/select-product.vue'
import selectSeckill from '@/components/select-list/select-seckill.vue'
import selectUser from '@/components/select-list/select-user.vue'
import selectBoostProduct from '@/components/select-list/select-boost-product.vue'
import selectIntegralProduct from '@/components/select-list/select-integral-product.vue'
import selectSecKillProduct from '@/components/select-list/select-secKill-product.vue'
import selectInstitution from '@/components/select-list/select-institution.vue'
import selectGroupDiscount from '@/components/select-list/select-group-discount.vue'
import selectBrand from '@/components/select-list/select-brand.vue'
import selectStaff from '@/components/select-list/select-staff.vue'
import selectGift from '@/components/select-list/select-gift.vue'
import readExcel from '@/components/common/read-excel.vue'
import importTableTools from '@/components/common/import-table-tools.vue'
import productAcquisition from '@/components/common/product-acquisition.vue'
import selectProductCouponPackage from '@/components/select-list/select-product-coupon-package.vue'

let componentUI = {
    install: (vue) => {
        // 单文件上传组件
        vue.component('attachmentSelectBtn', attachmentSelectBtn)
        // 单文件上传组件
        vue.component('attachmentSelect', attachmentSelect)
        // 单文件上传组件
        vue.component('upload', upload)
        // 单文件上传组件
        vue.component('imagePreview', imagePreview)
        // 列表分页组件
        vue.component('pagination', pagination)
        // 列表分页组件
        vue.component('select-page', selectPage)
        // 列表分页组件
        vue.component('list-search', listSearch)
        // 列表分页组件
        vue.component('list-tools', listTools)
        // 列表分页组件
        vue.component('tags-input', tagsInput)
        // 列表分页组件
        vue.component('load-page', loadPage)
        vue.component('select-product', selectProduct)
        vue.component('select-seckill', selectSeckill)
        vue.component('select-user', selectUser)
        vue.component('select-boost-product', selectBoostProduct)
        vue.component('select-integral-product', selectIntegralProduct)
        vue.component('select-institution', selectInstitution)
        vue.component('select-brand', selectBrand)
        vue.component('select-staff', selectStaff)
        vue.component('select-gift', selectGift)
        vue.component('read-excel', readExcel)
        vue.component('select-group-discount', selectGroupDiscount)
        vue.component('import-table-tools', importTableTools)
        vue.component('select-secKill-product', selectSecKillProduct)
        vue.component('product-acquisition', productAcquisition)
        vue.component('product-product-coupon-package', selectProductCouponPackage)
    }
}

export default componentUI