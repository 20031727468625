<template>
	<div>
		<el-select ref="selectRef" size="mini" :disabled="disabled" :multiple="multiple" clearable filterable remote
			:remote-method="remoteSearch" placeholder="请选择" v-model.trim="selectValue" @change="handleChange"
			@visible-change="handleVisible" @focus="focus" :loading="loading" style="width:100%">
			<div class="option">
				<el-option v-for="(item,index) in options" :key="index" :label="item.name" :value="item.id">
				</el-option>
			</div>
			<div class="pagin" v-if="pageNumberAll > 10">
				<el-pagination mini background layout="prev, pager, next, total" :page-size="pageSize"
					:total="pageNumberAll" :current-page="pageNumber" @current-change="page"> </el-pagination>
			</div>
		</el-select>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			// 绑定的值（双向绑定显示的值）
			select: {
				type: [String, Array, Number],
				default: ''
			},
			// 是否多选
			multiple: {
				type: Boolean,
				default: false
			},
			// 请求地址
			url: {
				type: String,
				required: true
			},
			// 是否禁用
			disabled: {
				type: Boolean,
				default: false
			},
			// 模糊查询关键字
			filterKey: {
				type: String,
				default: ''
			},
			// 下拉修改时使用到的参数
			editinfo: {
				type: Object,
				default: () => {}
			},
			// 行下标唯一标识
			rowIndex: {
				type: Number,
				default: undefined
			},
			// 下拉显示的文字
			label: {
				type: String,
				default: ''
			},
			// 下拉选择的值
			value: {
				type: [String, Array, Number],
				default: ''
			}
		},
		data() {
			// 这里存放数据
			return {
				options: [],
				selectValue: '',
				pageNumber: 1,
				pageSize: 10,
				pageNumberAll: 0,
				loading: false
			};
		},
		mounted() {
			// 当父组件上存在值时，对select值进行初始化
			if (this.selectValue == 0) {
				this.selectValue = '';
			} else {
				this.selectValue = this.select;
			}
			// 函数调用顺序要在options赋值为空数组之后
			// this.initData();
			// this.getOptionList();
		},
		// 方法集合
		methods: {
			// 处理值改变事件
			handleChange(value) {
				this.$emit('update:select', value);
				this.$emit('getStationType', this.rowIndex, this.tableData);
			},
			// 下拉框隐藏事件
			handleVisible(val) {
				// // 下拉框隐藏后重新加载下拉数据
				// if (!val) {
				// 	this.getOptionList();
				// }
			},
			// 过滤，实现模糊查询
			remoteSearch(value = '') {
				let params = {};
				let that = this;
				if (value) {
					params = {
						queryConfig: [{
							name: that.filterKey,
							condition: 'like',
							value: value
						}]
					};
				}
				this.getOptionList(params);
			},
			// 获取列表数据
			getOptionList(query = {}) {
				this.loading = true;
				this.options = [];
				let that = this;
				let params = Object.assign({
					pageSize: 30,
					pageNumber: 1
				}, query);
				this.$http.post(this.url, params)
					.then(({
						data: d
					}) => {
						if (d.code == 0) {
							this.pageNumberAll = d.data.total;
							this.tableData = d.data.records;
							d.data.records.forEach(item => {
								that.options.push({
									label: item[that.label],
									value: item[that.value]
								});
							});
							this.loading = false;
						}
					});
				// 这里主要针对修改操作，如果当前下拉显示的值没有在下拉选项数组里（因为options数组里的值是会变化的）
				// 显示时会将value转换成字符串然后显示，所有将这条修改的数据添加到选项数组中
				if (this.editinfo && (JSON.stringify(this.editinfo)) != '{}') {
					this.options.push(this.editinfo);
				}
			},
			focus(e) {
				this.getData();
			},
			// 初始化数据
			initData(e) {
				this.options = e.list
				this.pageNumberAll = e.total
			},
			getData() {
				this.$emit('query', {
					page: this.pageNumber,
					pageSize: this.pageSize,
				});
			},
			// 主表格分页点击事件
			page(page) {
				this.pageNumber = page;
				this.getData();
				// this.getOptionList(); // 更新
			}
		}
	};
</script>
<style lang="scss" scoped>
	// .select-main {
	// position: relative;

	.option {
		min-height: 50px;
		height: auto;
		max-height: 150px;
		overflow-y: auto;
	}

	.pagin {
		background: #fff;
	}

	// }

	::-webkit-scrollbar {
		width: 2px;
	}
</style>
