import service from "@/utils/service.js"

// 品牌类目列表
export function list (params) {
	return service({
		method: 'get',
		url: '/spread/productBrandCategory/list',
		params: params
	})
}

// 品牌类目新增
export function add (data) {
	return service({
		method: 'post',
		url: '/spread/productBrandCategory/add',
		data
	})
}

// 商品是否首页推荐
export function isIndexChange (data) {
	return service({
		method: 'post',
		url: '/spread/productBrandCategory/isIndexChange',
		data
	})
}

// 品牌类目选择列表
export function selectList (params) {
	return service({
		method: 'get',
		url: '/spread/productBrandCategory/selectList',
		params: params
	})
}

// 品牌类目详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/productBrandCategory/detail',
		params: params
	})
}

// 品牌类目修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/productBrandCategory/edit',
		data
	})
}

// 品牌类目状态切换
export function statusChange (data) {
	return service({
		method: 'post',
		url: '/spread/productBrandCategory/statusChange',
		data
	})
}

// 品牌类目删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/productBrandCategory/del',
		data
	})
}

// 列表拖拽排序
export function sorts (data) {
	return service({
		method: 'post',
		url: '/spread/productBrandCategory/setSorts',
		data
	})
}