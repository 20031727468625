import service from "@/utils/service.js";

/**
 * 员工
 * */
// 列表
export function list(params) {
    return service({
        method: 'get',
        url: '/spread/staff/list',
        params: params
    })
}


// 添加
export function add(data) {
    return service({
        method: 'post',
        url: '/spread/staff/add',
        data
    })
}

// 详情
export function getDetail(params) {
    return service({
        method: 'get',
        url: '/spread/staff/detail',
        params: params
    })
}

// 修改
export function edit(data) {
    return service({
        method: 'post',
        url: '/spread/staff/edit',
        data
    })
}

// 状态切换
export function statusChange(data) {
    return service({
        method: 'post',
        url: '/spread/staff/statusChange',
        data
    })
}

// 删除
export function del(data) {
    return service({
        method: 'post',
        url: '/spread/staff/del',
        data
    })
}

// 员工下级列表
export function getSubordinateList(params) {
    return service({
        method: 'get',
        url: '/spread/staff/subordinateList',
        params: params
    })
}

// 员工下级业绩
export function subordinateConsumption(params) {
    return service({
        method: 'get',
        url: '/spread/staff/subordinateConsumption',
        params: params
    })
}

// 员工解绑用户
export function delUser(data) {
    return service({
        method: 'post',
        url: '/spread/staff/delUser',
        data
    })
}