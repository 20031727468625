import service from "@/utils/service.js"

// 分类列表
export function categoryList (params = {}) {
	return service({
		method: 'get',
		url: '/spread/attachmentCategory/index',
		params: params
	})
}

// 附件列表
export function attachmentList (params) {
	return service({
		method: 'get',
		url: '/spread/attachment/list',
		params: params
	})
}

// 附件详情
export function getDetail (params) {
	return service({
		method: 'get',
		url: '/spread/attachment/detail',
		params: params
	})
}

// 附件删除
export function upload (data, onUploadProgress) {
	return service({
		method: 'post',
		url: '/spread/attachment/upload',
		data,
		onUploadProgress
	})
}


// 附件删除
export function del (data) {
	return service({
		method: 'post',
		url: '/spread/attachment/del',
		data
	})
}


// 附件归类
export function classify (data) {
	return service({
		method: 'post',
		url: '/spread/attachment/classify',
		data
	})
}


// 附件修改
export function edit (data) {
	return service({
		method: 'post',
		url: '/spread/attachment/edit',
		data
	})
}