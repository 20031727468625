import service from "@/utils/service.js"

// 获取上传token
export function getUploadToken (params) {
  return service({
    method: 'get',
    url: '/spread/upload/getUploadToken',
    params: params
  })
}


// 商品采集
export function productAcquisition (data) {
  return service({
    method: 'post',
    url: '/spread/product/acquisition',
    data
  })
}